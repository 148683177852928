import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import {
  ipdPatients,
  Encounter,
  Practitioner,
  Cancelled,
  Completed,
  Finished,
} from '../constants'
import {
  Dialog,
  IconButton,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  DialogTitle,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { startSxpProxy } from '../../../utils/api'
import '../bms.scss'
import '../../patients/dashboard/card.scss'
import {
  IPD_PROJECT_ID,
  OPD_PROJECT_ID,
  REGISTRATION_PROJECT_ID,
} from '../../../utils/constants'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { readableDateTimeFormat } from '../../../utils/dateUtils'
import { emptyString } from '../../Radiology/Constants/constants'
import { mappedPatientsIPD } from '../../lms/utils'
import UploadDocs from '../../patients/dashboard/UploadDocs'
import uploadIcon from '../../../assets/images/download_uplaod.png'
import patientDashboardIcon from './../../../assets/images/patientDashboardIcon.png'
import {
  allocateApi,
  tableHeadText,
  allocation,
  ipdVisitTableColumns,
} from '../modals'
import { ipVisitApi } from '../models'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import IpdTreatmentPlanForm from './IpdTreatmentPlanForm'
import IpdSummaryTabs from './IpdSummary/IpdSummaryTabs'
import './IpdSummary/IpdVisitSummary.scss'
import CloseIcon from '@mui/icons-material/Close'
import DoctorInfoDialog from '../../../components/DoctorInfoDialog'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'
import TransferDialog from '../../../components/TransferDialog'
import { toast } from 'react-toastify'
import {
  fetchSlotsDoctorsAsync,
  selectSlotsDoctors,
} from '../../slots/slotsSlice'
import {
  INTENSIVIST_COMMAND_CENTER,
  INTENSIVIST_COMMAND_CENTER_STATUS_OPTIONS,
  VISIT_STATUS_OPTIONS,
} from './Constants'
import { IP_VISIT_VITALS_URL } from '../endpoints/EndPoints'
import { getAssignedPatientForBed } from '../../../services/IPDService'
import { getProvenanceData } from '../../patients/utils'
import { Provenance } from 'fhir/r4'
import { ProvenanceData } from '../../patients/patientsSlice'
import ProvenanceView from '../../../components/ProvenanceView'
import { intent } from '../../administration/constants'
import { create, update } from '../../lms/constants'

type props = {
  patientId: string | null
  dataUpdated: boolean
  initialDisplay?: boolean
  bedId: string | null
  bedStatus: string | null
}
// interface Appointment {
//   id: string
//   resource: {
//     participant: {
//       individual: {
//         reference: string
//       }
//     }[]
//     period: {
//       start: string
//     }
//     resourceType: string
//     status: string
//     statusHistory: {
//       status: string
//     }[]
//   }
// }

interface Appointment {
  id: string
  resource: {
    participant: {
      individual: {
        reference: string
      }
      type: {
        coding: {
          code: string
        }[]
        text: string
      }[]
    }[]
    period: {
      start: string
    }
    resourceType: string
    status: string
    statusHistory: {
      status: string
    }[]
    subject: { reference: string }
  }
}

interface Practitioner {
  id: string
  resource: {
    id: string
    name: {
      given: string[]
      family: string
    }[]
    resourceType: string
  }
}

interface CloseEncounter {
  encounterId: string
  status: string
  reason?: string
}

const IPDPatientTable = ({
  patientId,
  initialDisplay,
  bedId,
  bedStatus,
}: props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [bedPatientList, setBedpatient] = useState<ipdPatients[]>([])
  const [locationName] = useState<string>('')
  const [status] = useState<string>('ASSIGNED')
  const [encounters, setEncounters] = useState<string>('')
  const [allAppointments, setAllAppointments] = useState<Appointment[]>([])
  const [allPractitioners, setAllPractitioners] = useState<Practitioner[]>([])
  const [allMedications, setAllMedications] = useState([])
  const [isTableVisible, setIsTableVisible] = useState(true)
  // const [closeVisit, setCloseVisit] = useState('')
  const [visitStatusChanged, setVisitStatusChanged] = useState(false)
  // const [treatmentForPhysioOpen, setTreatmentForPhysioOpen] = useState([])
  const [followUpAdvice] = useState('')
  const [followUpDate] = useState('')
  const [referralRequired] = useState('')
  const [openTreatment, setOpenTreatment] = useState(false)
  const [treatmentPlantext] = useState('')
  const [homeAdvice] = useState('')
  const [doctorName] = useState('')
  const [department, setDepartment] = useState('')
  const [reasonForReferal] = useState('')
  const [isOpenDocsPage, setIsOpenDocsPage] = useState(false)
  const [selectedPatientId, setSelectedPatientId] = useState<string>('')
  const [primaryInfo, setPrimaryInfo] = useState(false)
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [encounterCancel, setEncounterCancel] = useState({
    reason: '',
    encounterId: '',
    status: 'cancelled',
  })
  const [refresh, setRefresh] = useState(false)
  const [open, setOpen] = useState(false)
  const [doctor, setDoctor] = useState('')
  const [notes, setNotes] = useState('')
  const [otherDoctor, setOtherDoctor] = useState('')
  const [histroyDoctors, setHistroyDoctors] = useState([])
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})
  // const [filteredPatientId, setFilteredPatientId] = useState<ipdPatients[]>([])

  const handleCloseCancellation = () => {
    setOpenCancelDialog(false)
  }

  const doctors = useAppSelector(selectSlotsDoctors)

  useEffect(() => {
    dispatch(fetchSlotsDoctorsAsync())
  }, [dispatch])

  useEffect(() => {
    getTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const getData = () => {
  //   startSxpProxy(IPD_PROJECT_ID, allocateApi.getBedPatientAssignment, {}).then(
  //     async (data) => {
  //       const nodes = data.data?.bed_patient_assignment_map ?? []
  //       if (nodes.length) {
  //         const mapped = await mappedPatients(nodes)
  //         setBedpatient(mapped)
  //       }
  //     }
  //   )
  //   startSxpProxy(
  //     IPD_PROJECT_ID,
  //     allocateApi.getAssignedBedDetailsForAPatient,
  //     {
  //       patient_id: patientId,
  //     }
  //   ).then(async (data) => {
  //     const nodes = data.data?.bed_patient_assignment_map ?? []
  //     // setFilteredPatientId(nodes)
  //   })
  // }

  const getTableData = async () => {
    const response: any = await getAssignedPatientForBed(
      bedStatus,
      patientId,
      bedId
    )

    if (response?.success) {
      const newArray = []
      newArray?.push(response?.data)
      const mapped = await mappedPatientsIPD(newArray)
      setBedpatient(mapped)
    }
  }

  const transferDoctorHistory = () => {
    // console.log('transferDoctorHistory', filteredPatientId?.[0]?.id)
    // return
    startSxpProxy(IPD_PROJECT_ID, allocateApi.getDoctorHistory, {
      bed_patient_id: filteredPatientId?.[0]?.id,
    })
      .then(async (data) => {
        const nodes = data.data?.primary_doctors_transfer_map
        setHistroyDoctors(nodes)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const filtered = useMemo(() => {
    if (
      status === emptyString &&
      locationName === emptyString &&
      patientId === emptyString
    ) {
      return bedPatientList
    }
    return bedPatientList?.filter((item: ipdPatients) => {
      return (
        (status === emptyString || item?.bedStatus === status) &&
        (locationName === emptyString || item?.location === locationName)
      )
    })
  }, [bedPatientList, status, locationName, patientId])

  const closeEncounter = async (state: CloseEncounter) => {
    try {
      const intent = 'closeEncounter'
      await startSxpProxy(IPD_PROJECT_ID, intent, state)
      // setCloseVisit(resp?.data)
      setVisitStatusChanged((prev) => !prev)
      handleCloseCancellation()
    } catch (e) {
      console.error('Error closing encounter:', e)
    }
  }
  const handleStatusChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    encounterId: string
  ) => {
    if (e.target.value === 'finished') {
      closeEncounter({ status: 'finished', encounterId: encounterId })
    } else if (e.target.value === 'cancelled') {
      setOpenCancelDialog(true)
      setEncounterCancel((prevState) => ({
        ...prevState,
        encounterId: encounterId,
      }))
    }
  }

  const VisitTableRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const resizeHandler = () => {
      const container = VisitTableRef.current
      if (container) {
        const availableHeight =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 280}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [dispatch])

  const fetchAllAppointments = async (patientRegisteredId: string) => {
    const intent = ipVisitApi.getAllSubEncounters
    const state = { patientId: patientRegisteredId }
    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        if (data.data.entry?.length > 0) {
          const appointments = data?.data?.entry?.map((entry: any) => ({
            id: entry?.resource?.id,
            resource: entry?.resource,
          }))
          setAllAppointments(appointments)
          const EncounterIds = data?.data?.entry
            ?.map((entry: any) => {
              if (entry?.resource?.resourceType === Encounter) {
                return entry?.resource?.id
              }
            })
            ?.join(',')
          setEncounters(EncounterIds)
          const practitioners = data?.data?.entry?.filter(
            (entry: any) => entry?.resource?.resourceType === Practitioner
          )
          setAllPractitioners(practitioners)
        } else {
          setAllAppointments([])
        }
      })
      .catch((err) => {
        setAllAppointments([])
        console.error(err, 'err')
      })
  }

  const fetchProvencesOfVisits = useCallback(async () => {
    if (encounters) {
      startSxpProxy(REGISTRATION_PROJECT_ID, intent?.fetchProvenance, {
        patientIds: encounters,
      })
        ?.then((data) => {
          const entries =
            data?.data?.entry?.map(
              (dde: { resource: Provenance | Practitioner }) => dde?.resource
            ) ?? []

          const obj = getProvenanceData(entries)
          setProvenances(obj)
        })
        .catch((err) => console.error(err))
    }
  }, [encounters])

  useEffect(() => {
    fetchProvencesOfVisits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encounters])
  useEffect(() => {
    filtered?.forEach((d) => {
      if (d?.patientId === patientId) {
        fetchAllAppointments(d?.patient_registered_id)
      } else {
        setAllAppointments([])
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtered, patientId, visitStatusChanged])

  const dateObject = (dateString: string) => {
    const dateObject = new Date(dateString)
    return dateObject.toISOString().split('T')[0]
  }
  const handleDocClick = (patientId: string) => {
    setIsOpenDocsPage(true)
    setSelectedPatientId(patientId)
  }
  const handleCloseDialog = () => {
    setIsOpenDocsPage(false)
  }

  const toggleTable = () => {
    setIsTableVisible(!isTableVisible)
  }
  const differenceInDays = (d1: string, d2: any) => {
    const presentDate = new Date()
    const admittedDate = new Date(d1)
    const dischargedDate = new Date(d2)
    if (d2) {
      const DifferenceInTime = dischargedDate.getTime() - admittedDate.getTime()
      const DifferenceInDays = Math.round(DifferenceInTime / (1000 * 3600 * 24))
      return DifferenceInDays
    } else {
      const DifferenceInTime = presentDate.getTime() - admittedDate.getTime()
      const DifferenceInDays = Math.round(DifferenceInTime / (1000 * 3600 * 24))
      return DifferenceInDays
    }
  }

  const getPractitionerName = (practitionerId: string) => {
    const practitioner = allPractitioners.find(
      (prac) => prac?.resource?.id === practitionerId
    )

    return practitioner
      ? `${practitioner.resource.name?.[0]?.given?.join(' ')} ${
          practitioner.resource.name?.[0]?.family
        }`
      : 'Unknown'
  }
  useEffect(() => {
    if (allAppointments?.length > 0) {
      const encounterIds = allAppointments?.map((visitId: any) =>
        parseInt(visitId.id, 10)
      )
      getallVisitmedication(encounterIds)
    } else {
      setAllMedications([])
    }
  }, [allAppointments, refresh])

  // const getTreatmentPlan = async (encounterId: string) => {
  //   try {
  //     const intent = 'getTreatmentPlanForIPD'
  //     const state = { encounterId }
  //     const resp = await startSxpProxy(IPD_PROJECT_ID, intent, state)
  //     const treatmentPlan = resp?.data?.entry?.[0]?.resource?.component ?? []
  //     // setTreatmentForPhysioOpen(treatmentPlan)
  //     setOpenTreatment(true)
  //     setTreatmentPlan(
  //       treatmentPlan.find(
  //         (component: any) =>
  //           component?.code?.coding?.[0]?.code === 'treatmentPlan'
  //       )?.valueString ?? '-'
  //     )
  //     setFollowUpAdvice(
  //       treatmentPlan.find(
  //         (component: any) =>
  //           component?.code?.coding?.[0]?.code === 'followUpAdvice'
  //       )?.valueString ?? '-'
  //     )
  //     setDoctorName(
  //       treatmentPlan.find(
  //         (component: any) =>
  //           component?.code?.coding?.[0]?.code === 'nameOfDoctor'
  //       )?.valueString ?? '-'
  //     )
  //     setDepartment(
  //       treatmentPlan.find(
  //         (component: any) =>
  //           component?.code?.coding?.[0]?.code === 'department'
  //       )?.valueString ?? '-'
  //     )
  //     setReasonForReferal(
  //       treatmentPlan.find(
  //         (component: any) =>
  //           component?.code?.coding?.[0]?.code === 'reasonReferral'
  //       )?.valueString ?? '-'
  //     )
  //     setFollowUpDate(
  //       treatmentPlan.find(
  //         (component: any) =>
  //           component?.code?.coding?.[0]?.code === 'followUpDate'
  //       )?.valueString ?? '-'
  //     )
  //     setHomeAdvice(
  //       treatmentPlan.find(
  //         (component: any) =>
  //           component?.code?.coding?.[0]?.code === 'homeAdvice'
  //       )?.valueString ?? '-'
  //     )
  //     setReferralRequired(
  //       treatmentPlan.find(
  //         (component: any) =>
  //           component?.code?.coding?.[0]?.code === 'referralRequired'
  //       )?.valueString ?? 'Yes'
  //     )
  //   } catch (error) {
  //     console.error('Error fetching treatment plan details:', error)
  //   }
  // }

  const getallVisitmedication = async (visitIds: number[]) => {
    try {
      const intent = 'getallVisitmedication'
      const state = { visitIds: visitIds }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      const medications = resp?.data?.medications
      await setAllMedications(medications)
    } catch (e) {
      console.error('Error fetching medication details:', e)
      await setAllMedications([])
    }
  }
  const filteredPatientId = filtered?.filter(
    (d: ipdPatients) => d?.patientId === patientId
  )
  const handleNavigate = (d: Appointment, index: number) => {
    const referenceValue = d?.resource?.subject?.reference.split('/').pop()
    navigate(`${IP_VISIT_VITALS_URL}${d?.id}/vitals?regId=${referenceValue}`, {
      state: { status: true, index: index },
    })
  }

  const handleCloseTreatment = () => {
    setOpenTreatment(false)
  }
  const handleBack = () => {
    navigate('/bedIpd/bedAssignmentList')
  }

  const handlePrimaryInfo = () => {
    setPrimaryInfo(!primaryInfo)
    transferDoctorHistory()
  }

  const handleTransferInfo = () => {
    setOpen(!open)
  }
  const handleClose = () => {
    setDoctor('')
    setDepartment('')
    setOpen(false)
    setNotes('')
    setOtherDoctor('')
  }
  const handleDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setDepartment(event.target.value)

  const handleOtherDoctorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setOtherDoctor(event.target.value)

  const handleDoctorChange = (value: any) => {
    setDoctor(value?.name)
    setDepartment(value?.specialty)
  }

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value)
  }
  const handleSubmit = () => {
    if (!doctor) {
      toast.error('Please Select Doctor')
    } else if (!department) {
      toast.error('Please Enter Department')
    } else {
      startSxpProxy(IPD_PROJECT_ID, allocateApi.updatePrimaryDoctor, {
        patientId: filteredPatientId?.[0]?.patientId,
        assigned_doctor: otherDoctor ? otherDoctor + ' (Consultant) ' : doctor,
        initialDoctor: filteredPatientId?.[0]?.assigned_doctor,
        bedPatientId: filteredPatientId?.[0]?.id,
        docDepartment: department,
        notes: notes,
        initialDepartment: filteredPatientId?.[0]?.doctor_department,
      })
        ?.then(async (data) => {
          handleClose()
          // getData()
          getTableData()
          const nodes = data.data

          toast.success(nodes?.message)
        })
        ?.catch((error) => {
          toast.error(error?.response?.data?.message)
        })
    }
  }

  return (
    <>
      <DoctorInfoDialog
        onClose={handlePrimaryInfo}
        open={primaryInfo}
        rows={histroyDoctors}
      />

      <TransferDialog
        open={open}
        onClose={handleClose}
        doctors={doctors}
        department={department}
        doctor={doctor}
        otherDoctor={otherDoctor}
        notes={notes}
        onOtherDoctorChange={handleOtherDoctorChange}
        onDepartmentChange={handleDepartmentChange}
        onDoctorChange={handleDoctorChange}
        onNotesChange={handleNotesChange}
        onSubmit={handleSubmit}
      />

      <Dialog open={openCancelDialog} onClose={handleCloseCancellation}>
        <DialogTitle className='cancellation-visit-dialog-title'>
          Reason for cancellation
        </DialogTitle>
        <IconButton
          size='small'
          aria-label='close'
          onClick={handleCloseCancellation}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
        <DialogContent className='cancellation-visit-dialog-content'>
          <TextField
            name='reason'
            placeholder='Reason for cancellation'
            multiline
            rows={3}
            className='cancellation-visit-dialog-textField'
            onChange={(e) =>
              setEncounterCancel((prevState) => ({
                ...prevState,
                reason: e?.target?.value,
              }))
            }
          />
        </DialogContent>
        <DialogActions className='cancellation-visit-dialog-actions'>
          <Button
            variant='contained'
            size='small'
            className='cancellation-visit-dialog-actions-btn'
            onClick={() => closeEncounter(encounterCancel)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openTreatment} onClose={handleCloseTreatment}>
        <IconButton
          onClick={handleCloseTreatment}
          className='flex-end'
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            fontSize: '13px',
            padding: '4px',
          }}
        >
          x
        </IconButton>
        <div
          style={{
            paddingRight: '20px',
            paddingLeft: '20px',
            paddingBottom: '20px',
          }}
        >
          <IpdTreatmentPlanForm
            treatmentPlan={treatmentPlantext}
            followUpAdvice={followUpAdvice}
            followUpDate={followUpDate}
            homeAdvice={homeAdvice}
            referralRequired={referralRequired}
            doctorName={doctorName}
            department={department}
            reasonReferral={reasonForReferal}
          />
        </div>
      </Dialog>
      <div className='bedTableHead' style={{ marginBottom: '14px' }}>
        <table className='data-table table-fixed admin-table relative w100'>
          <thead className='sticky'>
            <tr>
              <th className='text-left w5'>{tableHeadText?.slNo}</th>
              <th className='text-center w12'>{tableHeadText?.uhid}</th>
              <th className='text-left w15'>{tableHeadText?.name}</th>
              <th className='text-center w10'>{tableHeadText?.mobileNo}</th>
              <th className='text-center w9'>{tableHeadText?.gender}</th>
              <th className='text-center w9'>{tableHeadText?.dob}</th>
              <th className='text-center w10'>{tableHeadText.bedNo}</th>
              <th className='text-center w13'>{tableHeadText.operatingUnit}</th>
              <th className='text-center w13'>{tableHeadText.department}</th>
              <th className='text-center w13'>
                {tableHeadText?.operatingUnit}
              </th>
              <th className='text-center w13'>{tableHeadText?.department}</th>
              {/* <th className='text-center w10'>{tableHeadText.status}</th> */}
              <th className='text-center w13'>
                <div className='info-text-container'>
                  <span className='info-text '>
                    {' '}
                    {tableHeadText?.primaryDoctor}
                  </span>
                  <IconButton onClick={handlePrimaryInfo} size='small'>
                    <InfoOutlinedIcon fontSize='small' className='info-icon' />
                  </IconButton>
                </div>
                {/* {tableHeadText.primaryDoctor}
                <span className='info-text'>
                  <InfoOutlinedIcon />
                </span> */}
              </th>
              {/* <th className='text-center w11'>{tableHeadText.admission}</th> */}
              <th className='text-center w11'>{tableHeadText?.discharge}</th>
              <th className='text-center w10'>{tableHeadText?.noOfDays}</th>
              <th className='text-center w13'>
                {tableHeadText?.patientdocuments}
              </th>
              <th className='text-center w7'>{tableHeadText?.view}</th>
            </tr>
          </thead>
          <tbody>
            {filteredPatientId?.length > 0 ? (
              filteredPatientId?.map((d: ipdPatients, i: number) => (
                <tr key={i}>
                  <td className='text-left'>{i + 1}</td>
                  <td className='text-center'>
                    {d?.patientId ? d?.patientId : allocation?.hyphen}
                  </td>
                  <td className='text-left'>{d?.patientName}</td>
                  <td className='text-center'>
                    {d?.patientMobile ?? allocation?.hyphen}
                  </td>
                  <td className='text-center'>
                    {d?.patientGender ?? allocation?.hyphen}
                  </td>
                  <td className='text-center'>
                    {d?.patientAge ?? allocation?.hyphen}
                  </td>
                  <td className='text-center'>{d?.bedNumber ?? emptyString}</td>
                  <td className='text-center'>{d?.location}</td>
                  <td className='text-center'>{d?.doctor_department}</td>
                  {/* <td className='text-center'>
                    {d?.bedStatus === allocation?.assigned
                      ? allocation?.admittedList
                      : allocation?.dischargedList}
                  </td> */}
                  <td className='text-center'>
                    <div className='info-text-container'>
                      <span className='info-text'> {d?.assigned_doctor}</span>
                      {d?.patientName ? (
                        <IconButton onClick={handleTransferInfo} size='small'>
                          <ExitToAppOutlinedIcon
                            className='info-icon1'
                            fontSize='small'
                          />
                        </IconButton>
                      ) : (
                        ''
                      )}
                    </div>
                  </td>
                  {/* <td className='text-center'>{dateObject(d?.dateCreated)}</td> */}
                  <td className='text-center'>
                    {d?.dateOfDischarge ? dateObject(d?.dateOfDischarge) : '-'}
                  </td>
                  <td className='text-center'>
                    {differenceInDays(d?.dateCreated, d?.dateOfDischarge)}
                  </td>
                  <td>
                    <div
                      onClick={() => handleDocClick(d?.patient_registered_id)}
                      className='uploadbtn'
                      style={{ textAlign: 'center' }}
                    >
                      <img
                        title='Upload Document`s'
                        src={uploadIcon}
                        alt='upload button'
                      />
                    </div>
                  </td>
                  <td className='text-center'>
                    <div className='flex-gap1 content-center'>
                      {/* <div>
                        <div className='uploadbtn'>
                          <img
                            title='Visits'
                            src={visiticon}
                            alt='Visits'
                            style={{ width: '15px' }}
                           // onClick={() => handleVisitClick(d?.patientId)}
                          />
                        </div>
                      </div> */}
                      <div>
                        <div className='uploadbtn'>
                          <img
                            title='patient Dashboard'
                            src={patientDashboardIcon}
                            alt='patient Dashboard'
                            style={{ width: '18px' }}
                            onClick={handleBack}
                          />
                        </div>
                      </div>
                    </div>
                    <Dialog
                      open={isOpenDocsPage}
                      onClose={handleCloseDialog}
                      maxWidth='sm'
                    >
                      <IconButton
                        onClick={handleCloseDialog}
                        className='content-end f13 ml-auto'
                      >
                        x
                      </IconButton>
                      <DialogContent
                        style={{ marginTop: '-2rem', padding: '20px' }}
                      >
                        <UploadDocs patientId={selectedPatientId} />{' '}
                      </DialogContent>
                    </Dialog>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={13} className='text-center'>
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div
        style={{ maxHeight: '700px', overflowY: 'auto' }}
        ref={VisitTableRef}
      >
        {initialDisplay && patientId && (
          <div className='bedTableHead' style={{ marginBottom: '7px' }}>
            <div className='font15' onClick={toggleTable}>
              Visit History {isTableVisible ? '▲' : '▼'}
            </div>
            {isTableVisible && (
              <div className='h-300'>
                <table className='data-table table-fixed admin-table relative w100'>
                  <thead className='sticky'>
                    <tr>
                      <th className='table-w-4'>
                        {ipdVisitTableColumns?.slNo}
                      </th>
                      <th className='text-center w15'>
                        {ipdVisitTableColumns?.docName}
                      </th>
                      <th className='text-center w20'>
                        {ipdVisitTableColumns?.dateTime}
                      </th>
                      <th className='table-w-8'>
                        {ipdVisitTableColumns?.createdBy}
                      </th>
                      <th className='table-w-8'>
                        {ipdVisitTableColumns?.updatedBy}
                      </th>
                      <th className='text-center w16'>
                        {ipdVisitTableColumns?.status}
                      </th>
                      <th className='text-center w10'>
                        {ipdVisitTableColumns?.viewVisit}
                      </th>
                      {/* <th className='text-center w13'>View Tr.Plan</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {allAppointments?.length === 0 ? (
                      <tr>
                        <td colSpan={5} className='text-center'>
                          No Visits
                        </td>
                      </tr>
                    ) : (
                      allAppointments
                        ?.filter(
                          (appointment) =>
                            appointment?.resource?.resourceType === Encounter
                        )
                        .sort((a, b) => Number(b?.id) - Number(a?.id))
                        .map((appointment, index) => {
                          const practitionerId =
                            appointment?.resource?.participant?.[0]?.individual?.reference.split(
                              '/'
                            )[1]
                          const practitionerName =
                            getPractitionerName(practitionerId)
                          const options =
                            practitionerName === INTENSIVIST_COMMAND_CENTER
                              ? INTENSIVIST_COMMAND_CENTER_STATUS_OPTIONS
                              : VISIT_STATUS_OPTIONS

                          return (
                            <tr key={appointment.id}>
                              <td
                                className='table-w-4'
                                style={{ padding: '6px', textAlign: 'center' }}
                              >
                                {index + 1}
                              </td>
                              <td className='table-w-8'>
                                {appointment?.resource?.participant?.[0]
                                  ?.type?.[0]?.text
                                  ? appointment?.resource?.participant?.[0]
                                      ?.type?.[0]?.text + ' ( Consultant )'
                                  : practitionerName}
                              </td>
                              <td className='text-center'>
                                {appointment?.resource?.period?.start
                                  ? readableDateTimeFormat(
                                      new Date(
                                        appointment?.resource?.period?.start
                                      )
                                    )
                                  : '-'}
                              </td>
                              <td>
                                <ProvenanceView
                                  date={
                                    provenances?.[appointment?.id]?.createdOn ??
                                    ''
                                  }
                                  name={
                                    provenances?.[appointment?.id]?.createdBy ??
                                    '-'
                                  }
                                  mode={create}
                                />
                              </td>
                              <td>
                                <ProvenanceView
                                  mode={update}
                                  date={
                                    provenances?.[appointment?.id]?.updatedOn ??
                                    ''
                                  }
                                  name={
                                    provenances?.[appointment?.id]?.updatedBy ??
                                    '-'
                                  }
                                />
                              </td>
                              <td className='text-center'>
                                {appointment?.resource?.statusHistory?.[0]
                                  ?.status === Finished ? (
                                  Completed
                                ) : (
                                  <select
                                    className='medication-input w45'
                                    key={appointment?.id}
                                    onChange={(e) =>
                                      handleStatusChange(e, appointment?.id)
                                    }
                                    style={{ fontSize: '12px', padding: '0px' }}
                                    disabled={
                                      appointment?.resource?.statusHistory?.[0]
                                        ?.status === Cancelled ||
                                      practitionerName ===
                                        INTENSIVIST_COMMAND_CENTER
                                    }
                                    value={
                                      appointment?.resource?.statusHistory?.[0]
                                        ?.status
                                    }
                                  >
                                    {options?.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                    {/* <option value={appointment?.id}>
                                      {appointment?.resource?.statusHistory?.[0]
                                        ?.status === 'triaged'
                                        ? 'InProgress'
                                        : capitalizeFirstLetter(
                                            appointment?.resource
                                              ?.statusHistory?.[0]?.status
                                          )}
                                    </option>
                                    <option value='finished'>Completed</option>
                                    <option value='cancelled'>Cancelled</option> */}
                                  </select>
                                )}
                              </td>
                              <td
                                className='header-view-text'
                                onClick={() =>
                                  handleNavigate(appointment, index)
                                }
                              >
                                View
                              </td>
                              {/* <td
                                className='header-view-text'
                                onClick={() => handleViewClick(appointment.id)}
                              >
                                View
                              </td> */}
                            </tr>
                          )
                        })
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
      <div className='ipd-tabs-conatiner'>
        <div className='ipd-tabs-heading-conatiner'>
          {allocation.ipVisitSummary}
        </div>
        <IpdSummaryTabs
          allMedications={allMedications}
          patientRegisteredId={patientId}
          setRefresh={setRefresh}
        />
      </div>
    </>
  )
}
export default IPDPatientTable
