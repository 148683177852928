import { useCallback, useEffect, useRef, useState } from 'react'
import {
  bedAssignmentList,
  emptyString,
} from '../../Radiology/Constants/constants'
import { ipdPatients } from '../constants'
import { tableHeadText, allocation } from '../modals'
import { useBeds } from './useBeds'
import { mappedPatientsIPD } from '../../lms/utils'
import '../bms.scss'
import { useNavigate } from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  IconButton,
  TablePagination,
  useTheme,
} from '@mui/material'
import uploadIcon from '../../../assets/images/download_uplaod.png'
import UploadDocs from '../../patients/dashboard/UploadDocs'
import patientDashboardIcon from './../../../assets/images/patientDashboardIcon.png'
import { setQueryParam } from '../IpdVisit/IpdVisitSlice'
import { useAppDispatch } from '../../../app/hooks'
import SelectableSearch from '../../../components/SelectableSearch'
import { BED_IPD_DASHBOARD_URL } from '../endpoints/EndPoints'
import ProvenanceView from '../../../components/ProvenanceView'
import { create, update } from '../../lms/constants'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { getAllBedAndPatientsByStatusAndLocation } from '../../../services/IPDService'

const styles = () => ({
  tablePagination: {
    marginRight: '6rem',
  },
})

const BedAssignmentList = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const dispatch = useAppDispatch()
  const locations = useBeds()
  const navigate = useNavigate()
  const [bedPatientList, setBedpatient] = useState<ipdPatients[]>([])
  const [locationName, setLocationName] = useState<string>('')
  const [status, setStatus] = useState<string>('ASSIGNED')

  const [isOpenDocsPage, setIsOpenDocsPage] = useState(false)
  const [selectedPatientId, setSelectedPatientId] = useState<string>('')
  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [totalElements, setTotalElements] = useState(0)
  const [paginationLoading, setPaginationLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    event?.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const dateObject = (dateString: string) => {
    const dateObject = new Date(dateString)
    const formattedDate = dateObject.toISOString().split('T')[0]
    return formattedDate
  }
  const differenceInDays = (d1: string, d2: any) => {
    const presentDate = new Date()
    const admittedDate = new Date(d1)
    const dischargedDate = new Date(d2)
    if (d2) {
      const DifferenceInTime = dischargedDate.getTime() - admittedDate.getTime()

      const DifferenceInDays = Math.round(DifferenceInTime / (1000 * 3600 * 24))
      return DifferenceInDays
    } else {
      const DifferenceInTime = presentDate.getTime() - admittedDate.getTime()

      const DifferenceInDays = Math.round(DifferenceInTime / (1000 * 3600 * 24))
      return DifferenceInDays
    }
  }

  const handleDocClick = (patientId: string) => {
    setIsOpenDocsPage(true)
    setSelectedPatientId(patientId)
  }
  const handleCloseDialog = () => {
    setIsOpenDocsPage(false)
  }

  const getTableData = useCallback(async () => {
    setPaginationLoading(true)
    const response: {
      data: any
      success: boolean
      message: string
    } = await getAllBedAndPatientsByStatusAndLocation(
      status,
      locationName,
      page,
      rowsPerPage,
      searchTerm
    )

    if (response?.success) {
      const mapped = await mappedPatientsIPD(response?.data?.data)
      setBedpatient(mapped)
      setPage(response?.data?.page)
      setRowsPerPage(response?.data?.size)
      setTotalElements(response?.data?.totalElements)
      setPaginationLoading(false)
    } else {
      setBedpatient([])
      setPage(0)
      setRowsPerPage(50)
      setTotalElements(0)
      setPaginationLoading(false)
    }
  }, [
    status,
    locationName,
    page,
    rowsPerPage,
    searchTerm,
    setBedpatient,
    setPage,
    setRowsPerPage,
    setTotalElements,
  ])

  const handlePatientDashboard = (
    e: string,
    id: string,
    bedId: number,
    bedStatus: string
  ) => {
    dispatch(setQueryParam({ patientId: e, id: id }))
    navigate(
      `${BED_IPD_DASHBOARD_URL}?id=${e}&bedId=${bedId}&bedStatus=${bedStatus}`
    )
  }
  const handleSearch = (query: string, param: string) => {
    setSearchTerm(param)
  }

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.target.value)
    setPage(0)
    setRowsPerPage(50)
  }

  const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLocationName(e.target.value)
    setPage(0)
    setRowsPerPage(50)
  }

  useEffect(() => {
    getTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, status, locationName, searchTerm])

  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 70}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <>
      <div className='bed-assignment-text'>
        <span>{bedAssignmentList}</span>
      </div>
      <div className='lms-filters mb2 mtminus23'>
        <div>
          <select
            className='category-filter'
            name='status'
            value={status}
            onChange={handleStatusChange}
          >
            <option value={allocation.assigned}>
              {allocation.admittedList}
            </option>
            <option value={allocation.dischargedText}>
              {allocation.dischargedList}
            </option>
            <option value={emptyString}>{allocation.all}</option>
          </select>
        </div>
        <div>
          <select
            className='category-filter'
            name='location'
            value={locationName}
            onChange={handleLocationChange}
          >
            <option value=''>{allocation.selectLocation}</option>
            {locations.map((op: any, i: number) => (
              <option key={i} value={op}>
                {op}
              </option>
            ))}
          </select>
        </div>
        <SelectableSearch
          items={['ID', 'Phone', 'Name']}
          onSearch={handleSearch}
        />
      </div>

      <div className='sub-container'>
        <div className='bedTableHead' ref={patientTableContainerRef}>
          <table className='data-table table-fixed admin-table relative w100'>
            <thead className='sticky'>
              <tr>
                <th className='table w6'>{tableHeadText?.slNo}</th>
                <th className='text-center w12 f11'>{tableHeadText?.uhid}</th>
                <th className='text-center  w15'>{tableHeadText?.name}</th>
                <th className='text-center w11'>{tableHeadText?.mobileNo}</th>
                <th className='text-center w8'>{tableHeadText?.gender}</th>
                <th className='text-center w8'>{tableHeadText?.age}</th>
                <th className='text-left w7'>{tableHeadText?.bedNo}</th>
                <th className='text-center w14'>
                  {tableHeadText?.operatingUnit}
                </th>
                <th className='text-center w10'>{tableHeadText?.status}</th>
                <th className='text-center w12'>{tableHeadText?.admission}</th>
                <th className='text-center w12'>{tableHeadText?.discharge}</th>
                <th className='text-center w10'>{tableHeadText?.noOfDays}</th>
                <th className='text-center w8'>
                  {tableHeadText?.patientdocuments}
                </th>
                <th className='text-center w8'>{tableHeadText.view}</th>
                <th className='text-center w12'>{tableHeadText?.createdBy}</th>
                <th className='text-center w12'>{tableHeadText?.updatedBy}</th>
              </tr>
            </thead>
            <tbody>
              {bedPatientList?.map((d: ipdPatients, i: number) => (
                <tr key={i}>
                  <td className='text-center'>{i + 1}</td>
                  <td className='text-center'>
                    {d.patientId ? d.patientId : allocation.hyphen}
                  </td>
                  <td className='text-left'>{d.patientName}</td>
                  <td className='text-left'>
                    {d.patientMobile ?? allocation.hyphen}
                  </td>
                  <td className='text-center'>
                    {d.patientGender === 'M'
                      ? 'Male'
                      : d.patientGender === 'F'
                      ? 'Female'
                      : 'Others'}
                  </td>
                  <td className='text-center'>
                    {d.patientAge ?? allocation.hyphen}
                  </td>
                  <td className='text-center'>{d.bedNumber ?? emptyString}</td>
                  <td className='text-center'>{d.location}</td>
                  <td className='text-center'>
                    {d.bedStatus === allocation.assigned
                      ? allocation.admittedList
                      : allocation.dischargedList}
                  </td>
                  <td className='text-center'>{dateObject(d?.dateStarted)}</td>
                  <td className='text-center'>
                    {d?.dateOfDischarge ? dateObject(d?.dateOfDischarge) : '-'}
                  </td>
                  <td className='text-center'>
                    {differenceInDays(d?.dateStarted, d?.dateOfDischarge)}
                  </td>
                  <td>
                    <div
                      onClick={() => handleDocClick(d.patient_registered_id)}
                      className='uploadbtn text-center'
                    >
                      <img
                        title='Upload Document`s'
                        src={uploadIcon}
                        alt='upload button'
                      />
                    </div>
                  </td>
                  <td
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      border: 'none',
                      justifyContent: 'center',
                    }}
                  >
                    {/* <div className='uploadbtn'>
                      <img
                        title='Visits'
                        src={visiticon}
                        alt='Visits'
                        style={{ width: '15px' }}
                        // onClick={() => handleVisitChange(d)}
                      />
                    </div> */}
                    <div className='uploadbtn'>
                      <img
                        title='patient Dashboard'
                        src={patientDashboardIcon}
                        onClick={() =>
                          handlePatientDashboard(
                            d?.patientId,
                            d?.patient_registered_id,
                            d?.bedId,
                            d?.bedStatus
                          )
                        }
                        alt='patient Dashboard'
                        style={{ width: '18px' }}
                      />
                    </div>
                  </td>
                  <td>
                    <ProvenanceView
                      mode={create}
                      date={d?.dateCreated ?? '-'}
                      name={d?.createdBy ?? '-'}
                    />
                  </td>
                  <td>
                    <ProvenanceView
                      mode={update}
                      date={d?.dateChanged ?? ''}
                      name={d?.updatedBy ?? ''}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        className={classes?.tablePagination}
        component='div'
        count={totalElements}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
        size='small'
        disabled={paginationLoading}
      />

      <Dialog open={isOpenDocsPage} onClose={handleCloseDialog} maxWidth='sm'>
        <IconButton onClick={handleCloseDialog} className='flex-end f12'>
          x
        </IconButton>
        <DialogContent className='margin2 padding20'>
          {' '}
          <UploadDocs patientId={selectedPatientId} />{' '}
        </DialogContent>
      </Dialog>
    </>
  )
}
export default BedAssignmentList
