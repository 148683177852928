import { useTheme } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import { Box } from '@mui/material'
import { ColDef, INumberFilterParams } from 'ag-grid-community'
import { startSxpProxy } from '../../utils/api'
import {
  CHAT_PROJECT_ID,
  OPD_PROJECT_ID,
  REGISTRATION_PROJECT_ID,
} from '../../utils/constants'
import MedAgDataGrid from '../../components/MedAgDataGrid'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getVisitsByDate, selectVisits } from './appointmentsSlice'
import { PageSize, visitsCountOP } from './models'
import { opdvisistIntent } from '../lms/constants'
import ProvenanceView from '../../components/ProvenanceView'
import { create, formValues, intent, update } from '../administration/constants'
import { useNavigate } from 'react-router-dom'
import { ProvenanceData } from '../patients/patientsSlice'
import { capitalizeFirstLetter, getProvenanceData } from '../patients/utils'
import { InProgress, triaged } from '../OPDScreen/constant'
import { Completed, Finished } from '../bedManagement/constants'
import { styles } from '../ProRithm/ProRithmDasboard'
import CustomHeader from '../Radiology/components/RadiologyModel/CustomHeader'

const VisitsNewTable = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [totalCount, setTotalCount] = useState(0)
  const visits = useAppSelector(selectVisits)
  const dispatch = useAppDispatch()
  const [patientData, setPatientData] = useState<any>(null)
  const [updatedData, setUpdatedData] = useState<any[]>([])
  const navigate = useNavigate()
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})
  const handleNameClick = (appointment: any) => {
    let visitLink = `/visits/${appointment.visitId}`
    if (appointment.visitType === 'general') {
      visitLink = `/visits/${appointment.visitId}/history`
    } else if (appointment.visitType === 'dental') {
      visitLink = `/visits/${appointment.visitId}/dental/historyDental`
    } else if (appointment.visitType === 'physio') {
      visitLink = `/visits/${appointment.visitId}/physio/historyPhysio`
    }
    navigate(visitLink, { state: { appointment } })
  }
  console.log(updatedData)
  const columnDefs: ColDef[] = [
    {
      headerName: 'Sl No',
      valueGetter: (params: any) => {
        return params.node ? params.node.rowIndex + 1 : null
      },
      width: 70,
      sortable: true,
      filter: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      headerComponent: CustomHeader,
    },
    {
      headerName: 'UHID',
      field: 'patientIdentifier',
      width: 120,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Name',
      field: 'patientName',
      width: 170,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      cellRenderer: (params: any) => (
        <a
          className='clickable-cell'
          onClick={() => handleNameClick(params?.data)}
        >
          {params?.value}
        </a>
      ),
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Mobile No',
      field: 'PatientNumber',
      width: 120,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Operating Unit',
      field: 'location',
      width: 160,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Service',
      field: 'service',
      width: 150,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Visit Type',
      field: 'visitType',
      width: 150,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      valueFormatter: (params: any) => capitalizeFirstLetter(params.value),
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Doc Name',
      field: 'docName',
      width: 150,
      sortable: false,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      headerComponent: CustomHeader,
    },
    {
      headerName: ' Date of Appointments',
      field: 'dateOfAppointment',
      width: 200,
      sortable: true,
      filter: 'agDateColumnFilter',
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      headerComponent: CustomHeader,
    },

    {
      headerName: 'Slot Timing',
      field: 'time',
      width: 150,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Created',
      field: 'createdBy',
      width: 150,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      cellRenderer: (params: any) => {
        const { createdOn, createdBy } = params.data
        const validCreatedOn =
          createdOn &&
          createdOn !== '-' &&
          !isNaN(new Date(createdOn)?.getTime())
            ? new Date(createdOn)?.toISOString()
            : null

        const date = validCreatedOn
        const name = validCreatedOn ? createdBy : ''
        const mode = validCreatedOn ? update : create
        if (date) {
          return (
            <div>
              <ProvenanceView date={date} name={name} mode={mode} />
            </div>
          )
        }
        return <div>{formValues?.na}</div>
      },
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Updated',
      field: 'updatedBy',
      width: 150,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      cellRenderer: (params: any) => {
        const { updatedOn, updatedBy } = params.data

        const validUpdatedOn =
          updatedOn &&
          updatedOn !== '-' &&
          !isNaN(new Date(updatedOn)?.getTime())
            ? new Date(updatedOn)?.toISOString()
            : null

        const date = validUpdatedOn
        const name = validUpdatedOn ? updatedBy : ''
        const mode = validUpdatedOn ? update : create

        if (date) {
          return (
            <div>
              <ProvenanceView date={date} name={name} mode={mode} />
            </div>
          )
        }

        return <div>{formValues?.na}</div>
      },
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Status',
      field: 'visitStatus',
      width: 100,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      valueGetter: (params: any) => {
        const status = params.data.visitStatus
        if (status === triaged) {
          return InProgress
        } else if (status === Finished) {
          return Completed
        }
        return status
      },
      headerComponent: CustomHeader,
    },
  ]
  const fetchVisits = async () => {
    try {
      await dispatch(
        getVisitsByDate({
          locationId: '',
          count: pageSize,
          offSet: page * pageSize,
        })
      )
    } catch (err) {
      console.error(opdvisistIntent?.visitErrorMsg, err)
    }
  }
  const fetchtotalCount = async () => {
    try {
      const data = await startSxpProxy(CHAT_PROJECT_ID, visitsCountOP)
      setTotalCount(data?.data?.total)
    } catch (err) {
      console.error('Error fetching total count:', err)
    }
  }

  const fetchPatientData = async () => {
    if (!patientIds.length) return

    try {
      const response = await startSxpProxy(
        OPD_PROJECT_ID,
        intent?.getPatientsByIdsApi,
        { ids: patientIds.join(',') }
      )
      setPatientData(response?.data)
    } catch (error) {
      console.error('Error fetching patient data:', error)
    }
  }
  const fetchProvenance = async () => {
    const patientIds = visits
      ?.map((d: any) => d?.visitId)
      ?.filter(Boolean)
      ?.join(',')

    startSxpProxy(REGISTRATION_PROJECT_ID, intent?.fetchProvenance, {
      patientIds: patientIds,
    })
      ?.then((data) => {
        const entries =
          data?.data?.entry?.map((dde: { resource: any }) => dde?.resource) ??
          []
        const obj = getProvenanceData(entries)
        setProvenances(obj)
      })
      ?.catch((err) => console?.error(err))
  }

  useEffect(() => {
    fetchVisits()
    fetchtotalCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize])

  const patientIds = useMemo(() => {
    return [...new Set(visits?.map((visit) => visit?.patientId) || [])]
  }, [visits])

  useEffect(() => {
    fetchPatientData()
    fetchProvenance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientIds])

  useEffect(() => {
    if (!patientData || !visits?.length) return

    try {
      const processedData = visits?.map((visit: any) => {
        const matchingEntry = patientData?.entry?.find(
          (entry: any) => visit.patientId === entry?.resource?.id
        )
        const visitProvenance = provenances?.[visit?.visitId]
        return {
          ...visit,
          patientIdentifier:
            matchingEntry?.resource?.identifier?.[2]?.value || '-',
          PatientNumber: matchingEntry?.resource?.telecom?.[0]?.value || '',
          patientName: matchingEntry
            ? `${matchingEntry?.resource?.name?.[0]?.given?.[0] || ''} ${
                matchingEntry?.resource?.name?.[0]?.family || ''
              }`
            : '',
          dateOfAppointment: new Date(visit?.date),
          createdOn: visitProvenance?.createdOn || '-',
          createdBy: visitProvenance?.createdBy || '-',
          updatedOn: visitProvenance?.updatedOn || '-',
          updatedBy: visitProvenance?.updatedBy || '-',
        }
      })

      const sortedData = processedData?.sort(
        (a: any, b: any) =>
          new Date(b?.date).getTime() - new Date(a?.date).getTime()
      )
      setUpdatedData(sortedData)
    } catch (error) {
      console.error('Error processing visit data:', error)
    }
  }, [visits, patientData, provenances])
  return (
    <Box height={'93%'} width={'100%'}>
      <Box
        className={classes?.proRithmDasboardContainer}
        height={'100%'}
        width={'100%'}
      >
        <Box
          className={classes?.proRithmDasboardTableContainer}
          height={'100%'}
          width={'100%'}
        >
          <MedAgDataGrid
            // tableHeading='Visits'
            tableHeight={480}
            searchField={false}
            exportIcon={false}
            rowData={updatedData}
            columnDefs={columnDefs}
            pagination={true}
            pageSizeOptions={PageSize}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalRows={totalCount}
            headerHeight={30}
            // filterActions={<FilterActions />}
            searchFieldWidth={200}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default VisitsNewTable
