export const wardType = {
  id: 'Id',
  ward: 'Ward Or Room',
  floor: 'Floor Number',
  location: 'Operating Unit',
  noWards: 'No  Wards Found',
  maximumBeds: 'Maximum Beds',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  actions: 'actions',
}
export const bedType = {
  id: 'Id',
  bedName: 'Bed',
  floor: 'Floor Number',
  roomName: 'Room',
  location: 'Operating Unit',
  bedCategory: 'Bed Category',
  noBeds: 'No Beds Found',
  seqNo: 'Seq.No',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  actions: 'Actions',
}

export const roomType = {
  id: 'Id',
  wardName: 'Ward Name',
  floor: 'Floor Number',
  roomNUmber: 'Room Number',
  roomName: 'Room Name',
  location: 'Operating Unit',
  noRooms: 'No Rooms Found',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  actions: 'Actions',
}

export const AdminIntent = {
  DeletepanelById: 'deletePanelById',
  InactiveTests: 'InactiveTests',
  DeletePackageById: 'DeletePackage',
  InactivePackage: 'inactivePackage',
  DeleteDoctor: 'deleteDoctor',
  getSpecialty: 'getSpecilities',
  DeleteSpecialty: 'deleteSpecailty',
  DeleteBed: 'deleteBed',
  ArchiveBed: 'toggleBedActive',
  DeleteRoom: 'deleteRoom',
  ArciveRoom: 'toggleRoomActive',
  DeleteWard: 'deleteWard',
  ArchiveWard: 'toggleWardActive',
  DeleteSample: 'deleteSample',
  ArchiveSample: 'toggleSampleStatus',
  DeleteLabTest: 'deleteLabTest',
  ArchiveLabTest: 'toggleLabTestStatus',
  DeleteLabDepartment: 'deleteLabDepartment',
  ArchiveLabDepartment: 'toggleDepartmentStatus',
  DeleteUnitType: 'deleteUnitType',
  ArchiveUnitType: 'toggleTestUnitTypeStatus',
  DeleteResultType: 'deleteTestResultType',
  ArchiveResultType: 'toggleTestResultType',
  DeleteMethodType: 'deleteTestMethod',
  ArchiveMethodType: 'toggleTestMethodTypeStatus',
  DeleteTestRange: 'deleteTestResultLimit',
  ArchiveTestRange: 'toggleParameterRangeStatus',
  DeletePathologist: 'deletePathologist',
  getPanelById: 'getPanelById',
}
export const referredTo = 'Referred To'
export const validatedOn = 'Validated on'
export const Labintent = {
  getReferredLabResults: 'getReferredLabResults',
  getPatientsByIdsApi: 'getPatientsByIdsApi',
  getOrderEventsByOrder: 'getOrderEventsByOrder',
}

export const create = 'CREATE'
export const update = 'UPDATE'
export const opdTableHeadings = {
  sl: 'Sl No',
  UHID: 'UHID',
  RegType: 'Reg.Type',
  Name: 'Name',
  MobileNo: 'Mobile No',
  Gender: 'Gender',
  Age: 'Age',
  OperatingUnit: 'Operating Unit',
  CreatedOn: 'Created On',
  UpdatedOn: 'Updated On',
  PatientDoc: 'Patient Doc',
  View: 'View',
}

export const opdvisistIntent = {
  visitErrorMsg: 'Error fetching visits:',
}

export const departmentTableHeadings = {
  id: 'Id',
  department: 'Department Name',
  description: 'Description',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  actions: 'Actions',
}

export const labTestTableHeadings = {
  id: 'Id',
  parameterName: 'Parameter Name',
  department: 'Department',
  sample: 'Sample',
  test: 'Test',
  resultType: 'Result Type',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  actions: 'Actions',
  parameter_short_form: 'Parameter Short Form',
}

export const methodTableHeadings = {
  id: 'Id',
  method: 'Method',
  description: 'Description',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  actions: 'Actions',
}

export const resultTypeTableHeadings = {
  id: 'Id',
  resultType: 'Result Type',
  description: 'Description',
  options: 'Options',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  actions: 'Actions',
}

export const sampleTableHeadings = {
  id: 'Id',
  sampleName: 'Sample Name',
  description: 'Description',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  actions: 'Actions',
}

export const testRangeTableHeadings = {
  id: 'Id',
  parameterName: 'Parameter Name',
  testName: 'Test Name',
  gender: 'Gender',
  method: 'Method',
  unitType: 'Unit Type',
  ageRange: 'Age Range',
  valueRange: 'Value Range',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  actions: 'Actions',
}

export const unitTypeTableHeadings = {
  id: 'Id',
  unit: 'Unit',
  description: 'Description',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  actions: 'Actions',
}

export const menuValues = {
  Doctor: 'doctor',
  Location: 'location',
  Bed: 'beds',
  Room: 'rooms',
  Ward: 'ward',
  LabDepartment: 'department',
  Method: 'method',
  Packages: 'packages',
  Parameter: 'labTest',
  ParameterRange: 'testRange',
  Pathologist: 'pathologist',
  ResultType: 'resultType',
  Sample: 'sample',
  Panel: 'panel',
  UnitType: 'unitType',
  Sequence: 'sequence',
  Partner: 'partner',
  parameterSequence: 'Parameter Sequence',
  none: 'none',
}
export const paragraphText = '<p><br></p>'
export const createLabOrderRoute = 'search/createorder/:id'
