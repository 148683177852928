import { ToastOptions } from 'react-toastify'

export type TestBase = {
  id: number
  name: string
  active: boolean
}

export type LabTest = TestBase & {
  panelId: number
  panelName: string
  sampleId: number
  sampleName: string
  description: string
  controlled?: boolean
}

export type Panel = TestBase & {
  sampleId: number
  sampleName: string
  radiologyTestName: string
  radiologyTestId: number
}

export type Sample = TestBase & {
  description: string
}

export type OrderEventTestType =
  | 'ORDERED'
  | 'ORDER_MODIFIED'
  | 'COLLECTED'
  | 'COLLECTION_APPROVED'
  | 'COLLECTION_REJECTED'
  | 'ENTERED'
  | 'ENTERED_REFERRED'
  | 'SUBMITTED'
  | 'APPROVED'
  | 'REJECTED'

export type OrderEventTest = {
  test_id: number
  status: OrderEventTestType
  updatedBy: string
  updatedOn: string
}

export type OrderEventType =
  | 'ORDERED'
  | 'ORDER_MODIFIED'
  | 'COLLECTION_IN_PROGRESS'
  | 'COLLECTION_COMPLETED'
  | 'COLLECTION_APPROVED'
  | 'COLLECTION_REJECTED'
  | 'RESULTS_ENTERED'
  | 'REFERRED_RESULTS_ENTERED'
  | 'RESULTS_SUBMITTED'
  | 'RESULTS_APPROVED'
  | 'RESULTS_REJECTED'
  | 'CANCELLED'

export type RadiologyOrderEvent = {
  id: number
  type: OrderEventType
  tests?: OrderEventTest[]
  comments?: string
  created_at: string
  updated_at: string
  updated_by: string
  order_id: string
  authorised_by_id: any
  authorised_by_name: string
}
export type RadiologyOrderTest = {
  id: number
  name: string
  description: string
  sampleId: string
  radiology_sample: any
}
export type RadiologyLabOrder = {
  id?: string
  visit_id?: number
  radiology_lab_tests?: RadiologyOrderTest[] | undefined
  lr_number?: string
  patient_id?: string
  patient_uhid?: string
  ordered_on?: string
  ordered_by_id?: string
  ordered_by_name?: string
  payment_status?: string
  validated_by_id?: string
  validated_by_name?: string
  status?: string
  source?: string
  price?: number
  package_id?: number | null
  summary?: string
  radiology_events?: RadiologyOrderEvent[]
  requested_by?: string
  origin?: string
}

export type RadiologyTest = TestBase & {
  sampleId: number
  description: string
}

export type LabMode = '' | 'package' | 'test'

export type AdminOption = {
  label: string
  value: string
}

export type FormMode = 'none' | 'create' | 'edit'

export type DashboardTab = 'collection' | 'testing' | 'validation' | 'dispatch'
export type RadiologyDashboardTab = 'testing' | 'validation' | 'dispatch'

export type AgeType = 'y' | 'm' | 'd' | null

export type AdminTableProps = {
  data: any
  onEdit: (id: string) => void
}

export type Organization = {
  id: number
  name: string
}

export type TestResultType = {
  id: number
  result_type: string
  options: string[]
}

export type TestResultLimit = {
  id: number
  gender: 'male' | 'female' | 'other' | null
  min_age: number
  max_age: number
  min_value: number
  max_value: number
  abnormal: string
  test_method: {
    id: number
    method_name: string
  }
  test_unit_type: {
    id: number
    unit_type: string
  }
  age_type: AgeType
}

export type CollectTest = {
  id: number
  name: string
  test_result_limits: TestResultLimit[]
  test_result_type: TestResultType
  panel: {
    id: number
    name: string
    lab_test_sequence: {
      sequence: number[]
    }
  }
}

export type CollectOrder = {
  id: number
  lr_number: string
  payment_status: string
  source: string
  status: 'ORDERED' | 'COLLECTED' | 'SUBMITTED' | 'REJECTED' | 'COMPLETED'
}

export type LmsObservation = {
  id: string
  title: string
  content: string
}

export type LmsValues = {
  summary: string
  observations: LmsObservation[]
}

export type LmsPanel = {
  id: number
  name: string
  sampleId: number
  sampleName: string
  disabled: boolean
  checked: boolean
  collectedBy: string
  collectedOn: string
  lab_tests: LabTest[]
}

export type RadiologyResult = {
  id: number
  patient_id: string
  radiology_order_id: number
  test_id: number
  test_name: string
  radiology_order: CollectOrder
  radiology_test_id: number
  radiology_test: any
  value: string
  extra_value: string
  values?: LmsValues
  result_limit_id: number
  referred_out: boolean | null
  referred_to: number | null
  organization: Organization
  collected_by: string
  collected_by_id: string
  collected_on: string
  results_entered_by: string
  results_entered_by_id: string
  results_entered_on: string
  test_status: 'COLLECTED' | 'ENTERED' | 'SUBMITTED' | 'APPROVED' | 'REJECTED'
  observation: string | null
  document_url: string
  rejected_on?: string
  rejected_by_id?: string
  rejected_by?: string
  rejection_reason?: string
  validated_by?: string
  validated_on?: string
  validated_by_id?: string
}

export type ResultCount = {
  total: number
  referred: number
  pending: number
}

export type RLabel = {
  name: string
  gender: string
  age: string
  patientId: string
  code: string
}

export type Package = {
  id: number
  name: string
  price: number
  panels: Panel[]
  lab_tests: LabTest[]
  active_from: string
  active_till: string
  created_by: string
  created_at: string
  updated_by: string
  updated_at: string
}

export type TestSequence = {
  id: number
  sequence: number[]
}

export type SequenceTest = {
  id: number
  name: string
}

export type SequencePanel = {
  id: number
  name: string
  lab_tests: SequenceTest[]
  lab_test_sequence?: TestSequence
}

export type InvoiceTest = {
  id: string
  name: string
  value: string
  enteredOn: string
  referred: string
  organization: {
    id: number
    name: string
  }
  unit: string
  range: number[]
  method: string
  abnormal: string
  extraValue: string
  values: LmsValues
  resultType: string
}
export type ModifiedTestPanel = {
  [key: string]: normal[]
}

export type panelObject = Record<string, testObject[]>

export type panelTests = {
  id: number
  name: string
  value: string
  enteredOn: string
  referredOn: string
  organization?: {
    id: number
    name: string
  }
  unit: string
  range: number[]
  abnormal: string
  method: string
  department: InvoiceDepartment
  resultType: string
  extraValue: string
  values: any
  interpretation?: string
}

export type testObject = {
  id: string | number
  name: string
  sequence?: number[] | null
  tests: panelTests[]
  department: InvoiceDepartment
  resultType: string
  interpretation?: string
}

export type InvoicePanel = {
  id: string
  name: string
  sequence: number[]
  tests: InvoiceTest[]
  department: InvoiceDepartment
  resultType: string
  interpretation: string
}

export type InvoiceDepartment = {
  id: string
  department_name: string
}
export type normal = {
  id: number
  name: string
  value: string
  enteredOn: string
  referredOn: string
  organisation: string | undefined
  unit: string
  range: number[]
  abnormal: string
  method: string
  department: InvoiceDepartment
  panel: panelOne
  resultType: string
  extraValue: string
  values: any
  interpretation: string
}
export type RadiologyOrganization = {
  id: number
  name: string
  isLab: boolean | null
}
export type panelOne = {
  id: number
  name: string
  interpretation: string | null
  lab_test_sequence?: { sequence: number[] }
}
export type InvoiceData = {
  id: string
  name: string
  age: string
  gender: string
  mobile: string
  requestedBy: string
  lrNumber: string
  orderedOn: string
  completedOn: string
  validatedOn: string
  panels: panelObject[]
  summary: string
  orderType: string
}

export const toastOptions: ToastOptions = {
  position: 'bottom-center',
  hideProgressBar: true,
  closeButton: false,
  theme: 'dark',
  autoClose: 2000,
  bodyStyle: { padding: 0 },
}

export type PatientVisit = {
  id: string
  source: string
  doctor: string
  specialty: string
  date: string
}
export const membership = 'Membership'
export const packageName = 'Package'
export const noOrdersFound = 'No Orders found'
export const noMembersFound = 'No Members found'
export const noTestsFound = 'No Tests found'
export const printLogo = 'Print Label'
export const noData = '-'
export const emptyString = ''
export const loadingString = 'Loading Orders...'
export const collectSamples = 'Collect Samples'
export const checked = 'Checked'
export const category = 'Category'
export const appointments = 'Appointments'
export const createAppointment = 'Create Appointment'
export const bedalloction = 'Bed Alloction'
export const bedAssignmentList = 'IP Patients'
export const bedoverview = 'Bed Allocation'
export const bedManagementDashboard = 'Bed Dashboard'
export const radiology = 'Radiology'
export const radiologist = 'Radiologist'
export const partner = 'Partner'
export const partnerForm = 'Partner Form'
export const lmsdashboard = 'LMS Dashboard'
export const createlaborders = 'Create LabOrders'
export const createRadiologyOrder = 'Create Radiology Orders'
export const patientText = 'patient'
export const radiologyComments = 'Comments:'
export const modes = {
  ordered: 'ordered',
  collected: 'collected',
  completed: 'completed',
  submitted: 'submitted',
  tested: 'tested',
  ordersToCollect: 'Orders to Collect',
  ordersToTest: 'orders to Test',
  ordersToDispatch: 'Orders to Test/Dispatch',
  completedOrders: 'Completed Orders',
  modifiedOrder: 'ORDER_MODIFIED',
  package: 'package',
  test: 'test',
  mode: 'mode',
}
export const activeTabs = {
  ordered: 'ordered',
  testing: 'testing',
  completed: 'completed',
}
export const tabs = {
  ordered: 'ordered',
  testing: 'testing',
  validation: 'validation',
  dispatch: 'dispatch',
}
export const samplesHeading = 'Samples'
export const noSamplesFound = 'No Samples Found'
export const remove = 'remove'
export const removePatient = 'Register Patient'
export const previous = 'Previous'
export const next = 'Next'
export const tests = 'Tests'
export const visit = 'visit'
export const enterResults = 'Enter Results'
export const createprovenance = 'CREATE'
export const createPackages = 'Create'
export const view = 'View'
export const activeText = 'active'
export const inactiveText = 'inactive'
export const radiologyActiveText = ' active'
export const save = 'Save'
export const deleted = 'Deleted'
export const create = 'Create'
export const noPatientsFound = 'No Patients Found'
export const noRadiologySamplesFound = 'No radiology samples Found'
export const selectTests = ' Select Tests'
export const sampleName = 'Samples Names'
export const testName = 'Test Name'
export const selectPackage = 'Select Package'
export const testParameters = 'Test Parameters'
export const selectedTests = 'SelectedTests'
export const labSamplesCollected = 'Lab Samples Collected'
export const noTestParameters = 'No Test Parameters Found'
export const noTestSelected = 'No Test Selected'
export const panelSelected = ' - Panel Selected'
export const selected = ' - Selected'
export const searchHolder = 'Search by Id or Name or Source'
export const testSearchHolder = 'Search by panel name or test name'
export const createOrderText = 'Create Order'
export const patient = 'patient'
export const packageLabel = 'Package'
export const testLabel = 'Test'
export const radiologyOrdersText = 'radiologyOrders'
export const radiologyOrdersHistory = 'Radiology Orders History'
export const visibleUpArrow = '▲'
export const visibleDownArrow = '▼'
export const radiologyMessage = 'Radiology Tests Saved'
export const goBackString = 'Go Back'
export const backToSamples = 'Back to Samples'
export const selectCategory = 'Select Category'
export const dateFormatText = 'DD-MM-YYYY'
export const viewLess = '...View Less'
export const viewMore = '...View More'
export const changeMode =
  'Do you wish to change mode? This will remove selected lab tests'
export const externalRequest = 'Requested By is mandatory for external tests...'
export const originRequest = 'Origin is mandatory for external tests...'
export const success = 'success'
export const error = 'error'
export const opd = 'OPD'
export const searchError = 'Search text should be at least 3 characters long'
export const noResultsfound = 'No Results found'
export const matchedTestsFound = 'Matched Tests Found'
export const loadingResults = 'Loading Results ...'
export const patientIdentifier = 'patient-identifier'
export const chooseOption = 'Choose an option'
export const resultsLoading = 'Loading Results'
export const search = 'Search'
export const titleRequired = 'Title is required'
export const contentRequired = 'Content is required'
export const obsSelected = 'selected'
export const threeDot = '...'
export const thopIdText = 'ThopId'
export const mobileText = 'mobile'
export const addDetails = 'Add Details'
export const statusentered = 'ENTERED'
export const statussubmitted = 'SUBMITTED'
export const provenanceCreate = 'CREATE'
export const provenanceUpdate = 'UPDATE'
export const radiologyTestForm = 'Radiology Test Form'
export const radiologyText = 'radiology'
export const unknown = 'Unknown'
export const referred = 'referred'
export const pageSizeOptions = [10, 25, 50, 100]
export const referredPageOptions = [5, 10, 15, 20, 25]
export const enterKey = 'Enter'
export const loadingText = '...Loading'
export const prefix = 'prefix'
export const opdObj = { value: 'OPD', label: 'OPD' }
export const originOptions = [
  { value: 'OPD', label: 'OPD' },
  { value: 'IPD', label: 'IPD' },
]
export const modeOptions = [
  { value: '', label: 'Select Mode' },
  { value: 'test', label: 'Test' },
  { value: 'package', label: 'Package' },
]
export const radiologyActions = {
  create: 'create',
  delete: 'delete',
}
export const intent = {
  getDoctorByVisit: 'getDoctorByVisit',
  getPatientVisits: 'getPatientVisits',
  getPatientsByIdsApi: 'getPatientsByIdsApi',
  getRadiologySamplesAPI: 'getRadiologySamplesAPI',
  getRadiologyOrdersByPatientId: 'getRadiologyOrdersByPatientId',
  getPatientApi: 'getPatientApi',
  getRadiologyOrdersByPatientIdAndStatus:
    'getRadiologyOrdersByPatientIdAndStatus',
}
export const radiologyIntents = {
  getAllRadiologyOrdersByPatientIds: 'getAllRadiologyOrdersByPatientIds',
  radiologyBacklogOrders: 'getBacklogRadiologyOrders',
  generateGlobalId: 'generateGlobalId',
  getRadiologyLabTestById: 'getRadiologyLabTestById',
  createRadiologyEvent: 'createRadiologyEvent',
  getDoctorByVisit: 'getDoctorByVisit',
  getPatientVisits: 'getPatientVisits',
  getRadiologyOrdersByDate: 'getRadiologyOrdersByDate',
  getPatientsByIdsApi: 'getPatientsByIdsApi',
  getRadiologySamplesAPI: 'getRadiologySamplesAPI',
  getRadiologyOrdersByPatientId: 'getRadiologyOrdersByPatientId',
  getRadiologyOrdersByPatientIdAndStatus:
    'getRadiologyOrdersByPatientIdAndStatus',
  getAllRadiologyPackages: 'getAllRadiologyPackages',
  getRadiologyOrderById: 'getRadiologyOrderById',
  getPatientApi: 'getPatientApi',
  getRadiologyOrdersByDispatch: 'getRadiologyOrdersByDispatch',
  getRadiologyOrdersByValidation: 'getRadiologyOrdersByValidation',
  updateRadiologyResults: 'updateRadiologyResults',
  getRadiologyResultsByOrderId: 'getRadiologyResultsByOrderId',
  updateShortRadiologyOrderId: 'updateShortRadiologyOrderId',
  getRadiologyOrdersByVisitId: 'getRadiologyOrdersByVisitId',
  getVisitApi: 'getVisitApi',
  getAllRadiologyTestsBySampleId: 'getAllRadiologyTestsBySampleId',
  getRadiologyTestsAPI: 'getRadiologyTestsAPI',
  getReferredRadiologyResults: 'getReferredRadiologyResults',
  updateRadiologyOrderById: 'updateRadiologyOrderById',
  createRadiologyOrder: 'createRadiologyOrder',
  getRadiologyResultsByOrderIds: 'getRadiologyResultsByOrderIds',
  getRadiologyOrdersForPatientForOpd: 'getRadiologyOrdersForPatientForOpd',
  createRadiologyHandOverDetails: 'createRadiologyHandOverDetails',
  getRadiologists: 'getRadiologists',
  getRadiologyDepartments: 'getRadiologyDepartments',
  createRadiologist: 'createRadiologist',
  getAllRadiologyOrdersByIpd: 'getAllRadiologyOrdersByIpd',
  getRadiologyPackageById: 'getRadiologyPackageById',
  updateRadiologyPackage: 'UpdateRadiologyPackageById',
  createRadiologyPackage: 'createRadiologyPackage',
  getRadiologyTestById: 'getRadiologyTestById',
  updateRadiologyLabTest: 'updateRadiologyLabTest',
  createRadiologyLabTests: 'createRadiologyLabTests',
  getRadiologyOrganizations: 'getRadiologyOrganizations',
  getRadiologistById: 'getRadiologistById',
  editRadiologist: 'editRadiologist',
}
export const formNameValues = {
  from: 'from',
  till: 'till',
}
export const alertMessages = {
  packageFrom: 'The "From" date must be earlier than the "Till" date.',
  packageTill: 'The "Till" date must be later than the "From" date.',
  validName: 'Enter a valid Name',
  validPrice: 'Enter a valid Price',
  validDate: 'Enter a valid From Date',
  addTest: 'select any test to add',
  savePackage: 'Package saved successfully',
  errorPackage: 'Error saving package',
}
export const dialogMessages = {
  replaceDocument: 'Replace Document',
  testsToBeRemoved: 'Tests To Be Removed',
  backToDashboard: 'Back To Dashboard',
  goToDashboard: 'Do You Want To Go Back To Dashboard',
  unknownUpdateType: 'Unknown update type:',
  documentUploaded:
    'A document is already uploaded. Do you want to replace it with a new one?',
  testToBeRemoved:
    'The Following Selected Tests To Be Removed If You Click On Yes.How Do You Want To Proceed?',
  referredTestSaved:
    'Test is Referred out and values are saved.Ready for Submit',
  noReferredValues: 'Test is Referred out .But values are not entered',
  savedTest: 'Test Saved.Ready for Submit',
  notReadyForSubmit:
    'Test is saved.But Not Ready to submit.please add Summary ',
  rejectedTest: 'Test is Rejected ',
  readyToApprove: 'Test is Submitted.Ready to Approve ',
  testApproved: 'Test is Approved ',
  modifedRadiologyOrder: 'Radiology Order Modified Successfully',
  createRadiologyOrder: 'Radiology Order Created Successfully',
  addOneTest: 'Please add atleast one test to submit',
  ipdRunTimeException: 'Runtime Exception.Redirecting to IPD Dashboard',
  phoneErrorMsg: 'Phone number must be exactly 10 digits',
  anotherPackage:
    'The following tests are already included in another package:',
  proceedWithPackage:
    'Do you want to proceed with this package which contains common tests that are included in another selected package?',
  testListToBeRemoved: 'List Of Tests To Be Removed',
  replaceTestWithPackage:
    'The following tests which you want to replace with packge:',
  proceedTestWithPackage: 'Do you want to proceed with package?',
  reasonToSelect: 'Enter a reason for all selected tests to continue---',
  reasonForNotReferred: 'Enter a reason to continue for not referred',
  selectSample: 'Please select a Sample Name before adding.',
  selectTest: 'Please select a Test Name before adding.',
  selectPackage: 'Please select a package before adding.',
  selectRequestedBy: 'Please select Requested By before adding.',
  selectMode: 'Please select a Mode before adding.',
  referMandatory: 'referredTo is mandatory when referred is checked',
  radiologyResultsSaved: 'Radiology Results Saved',
  radiologyResultsSubmitted: 'Radiology Results Submitted',
  selectRadiologists: 'select Radiologists to continue',
  selectRadiologistForNotReferred: 'select Radiologists for not refered',
  radiologyOrderValidated: 'Radiology Order Validated',
  radiologyRejected: 'Radiology Results Validated [Some Rejected]',
  undefinedPatient: 'Patient ID is undefined',
  searchInputError: 'Search input must be more than 4 characters long',
  noResultsWithUHID: 'No Results found with UHID or No Referral Results',
  checkPreviousTests:
    'Please wait while loading previuos tests if order exists..',
  fetching: 'please wait while Fetching ...',
}

export const radiologyMenu = {
  dashboard: 'Dashboard',
  search: 'Search',
  referredOut: 'Referred Out',
}
export const activeButtons = {
  validation: 'Validation',
  testing: 'Testing',
  completed: 'Dispatch',
}
export const statuses = {
  ordered: 'ORDERED',
  completed: 'COMPLETED',
}

export const tableHeadText = {
  id: 'Id',
  make: 'Make',
  model: 'Model',
  port: 'Port',
  IpAddress: 'IpAddress',
  subnetMask: 'SubnetMask',
  patientId: 'UHID',
  name: 'Name',
  source: 'Operating Unit',
  mobile: 'Mobile No.',
  orderType: 'Service',
  orderedOn: 'Ordered On',
  tests: 'Tests',
  category: 'Category',
  actions: 'Actions',
  orderedBy: 'Ordered By',
  department: 'Department',
  description: 'Description',
  handledAt: 'Handled At',
  interpretation: 'Interpretation',
  price: 'Price',
  status: 'Status',
}
export const radiologyTestStatus = {
  ordered: 'ORDERED',
  orderModified: 'ORDER_MODIFIED',
  entered: 'ENTERED',
  deleted: 'DELETED',
  completed: 'COMPLETED',
  reEntered: 'RE_ENTERED',
  reOrdered: 'REORDERED',
  approved: 'APPROVED',
  submitted: 'SUBMITTED',
  rejected: 'REJECTED',
  referredEntered: 'REFERRED_ENTERED',
}
export const radiologyConditions = {
  checked: 'checked',
  unChecked: 'unChecked',
  approved: 'approved',
  unApproved: 'unApproved',
  referred: 'referred',
}
export const originModes = {
  ipd: 'IPD',
  opd: 'OPD',
}
export const radiologyModes = {
  test: 'test',
  package: 'package',
}
export const radiologyRoute = {
  location: '/radiology/search',
}
export const radiologyButtons = {
  yes: 'Yes',
  no: 'No',
  update: 'Update',
  add: 'Add',
  viewLess: 'View Less',
  viewMore: 'View More',
  close: 'Close',
  create: 'Create',
  proceed: 'Proceed',
  cancel: 'Cancel',
}
export const genderConstants = {
  male: 'male',
  capitalMale: 'M',
  female: 'female',
  capitalFemale: 'F',
  capitalOthers: 'O',
}
export const registrationConstants = {
  other: 'Other',
  invalidRegistrationType: 'Invalid Registration Type',
  fr: 'fr',
  full: 'Full',
  fullRegistration: 'Full Registration',
  qr: 'qr',
  quick: 'Quick',
  quickRegistration: 'Quick Registration',
  erShort: 'er',
  er: 'ER',
  emergency: 'Emergency',
  pmc: 'pmc',
  pharmacy: 'Pharmacy',
  lab: 'Lab',
  labShort: 'lab',
  laboratory: 'Laboratory',
  diagnostics: 'Diagnostics',
  dgs: 'dgs',
  campaign: 'Campaign',
  camp: 'camp',
  membershipShort: 'membership',
  membershipPrimary: 'membershipPrimary',
  membership: 'Membership',
  packageshort: 'package',
  package: 'Package',
}
export const radiologyMainMenu = {
  radiologist: 'radiologist',
  radiologistRoute: '/radiologist',
  adminRadiologist: 'administration/radiologist',
  pathologist: 'pathologist',
  pathologistRoute: '/pathologist',
  adminPathologist: 'administration/pathologist',
  labDevices: 'labDevices',
  adminLabDevices: '/labDevices',
  radiologyDevices: 'radiologyDevices',
  adminRadiologyDevices: '/radiologyDevices',
  administration: '/administration',
}
export const handleRadiologyUpdate = {
  cancelEdit: 'cancelEdit',
  docUrlChange: 'docUrlChange',
  referredOutChange: 'referredOutChange',
  referredToChange: 'referredToChange',
  valuesChange: 'valuesChange',
  enter: 'enter',
  valueChange: 'valueChange',
}
export const SALUTATION_OPTIONS = [
  { value: 'Mr', label: 'Mr' },
  { value: 'mrs', label: 'Mrs' },
  { value: 'miss', label: 'Miss' },
  { value: 'dr', label: 'Dr' },
  { value: 'master', label: 'Master' },
  { value: 'baby', label: 'Baby' },
  { value: 'babyof', label: 'Baby of' },
]
export const radiologyTableButtons = {
  clear: 'clear',
}
