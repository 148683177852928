import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './component.scss'
import {
  ADMIN_PROJECT_ID,
  COMMANDCENTER_URL,
  INNOMAINT_URL,
  INNOMAINT_URL_QR_CODE,
  REACT_APP_EMP360,
  REPORT_URL,
  RMS_URL,
  suiteCRM,
} from '../utils/constants'
import {
  APPOINTMENTS_READ,
  APPOINTMENTS_WRITE,
  PATIENTS_READ,
  PATIENTS_WRITE,
  LMS_READ,
  IPD_READ,
  ADMINISTRATION_READ,
  RADIOLOGY_WRITE,
} from '../utils/roles'
import KeycloakService from '../utils/keycloakService'
import { startSxpProxy } from '../utils/api'
import { selectSelectedLocationId } from '../features/location/locationSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import {
  bedManagementOptions,
  labOptions,
  radiologyOptions,
} from '../features/lms/components/admin/AdminConfig'
import {
  setLmsItem,
  setLmsAdminSearch,
  setLmsFormMode,
  setSelectedValue,
} from '../features/lms/lmsSlice'
import {
  devicesOptions,
  intent,
  MainMenuConst,
  partnersOptions,
  subdropdownitems,
} from './constants'
import './MainMenu.css'
import {
  doctor,
  labDevices,
  partners,
  pathologist,
  proRithmDashboard,
  radilogist,
  radiologyDevices,
} from '../features/bedManagement/endpoints/EndPoints'
interface MenuItem {
  name: string
  label: string
  link: any
  roles?: string[]
  dropdownItems?: DropdownItem[]
}

interface DropdownItem {
  name: string
  label: string
  link: any
  onClick?: () => void
  subMenuItems?: DropdownItem[]
  isExternal?: boolean
}
interface SubdropdownRefs {
  [key: string]: HTMLDivElement | null
}

const MainMenu = () => {
  const dispatch = useAppDispatch()
  const [hoveredItem, setHoveredItem] = useState<string | null>(null)
  const [hoveredDropdownItem, setHoveredDropdownItem] = useState<string | null>(
    null
  )
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<
    string | null
  >(null)
  const [BillingUrl, setBillingurl] = useState('')
  const [pharmacyUrl, setPharmacyUrl] = useState('')
  const locationId = useAppSelector(selectSelectedLocationId)
  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number
    left: number
  }>({ top: 0, left: 0 })
  const fetchAuthLink = async (
    intent: string,
    location: string,
    setUrl: (url: string) => void
  ) => {
    try {
      const data = await startSxpProxy(ADMIN_PROJECT_ID, intent, { location })
      setUrl(data?.data?.auth_link || '')
    } catch (err) {
      console.error(err, 'err')
    }
  }

  useEffect(() => {
    fetchAuthLink(intent?.odooLogin, locationId, setBillingurl)
  }, [locationId])

  useEffect(() => {
    fetchAuthLink(intent?.pharmacyLocation, locationId, setPharmacyUrl)
  }, [locationId])

  const handleMouseEnter = (itemName: string, top: number, left: number) => {
    setHoveredItem(itemName)
    setDropdownPosition({ top, left })
  }

  const handleMouseLeave = () => {
    setHoveredItem(null)
    setHoveredDropdownItem(null)
  }

  const subdropdownRef = useRef<SubdropdownRefs>({})
  const handleDropdownItemEnter = (
    itemName: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setHoveredDropdownItem(itemName)

    const ref = subdropdownRef.current[itemName]
    if (ref) {
      const dropdownItemRect = event?.currentTarget?.getBoundingClientRect()
      const dropdownRect = event?.currentTarget
        ?.closest('.location-dropdown')
        ?.getBoundingClientRect()

      if (dropdownRect) {
        const viewportWidth = window?.innerWidth
        const subDropdownWidth = ref?.getBoundingClientRect()?.width
        const dropdownItemRectRight = dropdownItemRect?.right
        const dropdownItemRectTop = dropdownItemRect?.top
        ref.style.position = 'absolute'
        ref.style.top = `${dropdownItemRectTop - dropdownRect.top}px`
        if (dropdownItemRectRight + subDropdownWidth > viewportWidth) {
          ref.style.left = `-${subDropdownWidth}px`
        } else {
          ref.style.left = `${dropdownItemRect.width}px`
        }
      }
    }
  }

  const handleDropdownItemLeave = () => {
    setHoveredDropdownItem(null)
  }
  const hasRole = (roles: string[]) => {
    return roles.every(() => KeycloakService.hasRole(roles))
  }
  const splitRadiology = hoveredDropdownItem
  const options = useMemo(() => {
    let opts: { value: string; label: string }[] = []

    if (splitRadiology === subdropdownitems?.radiology) {
      opts = [...radiologyOptions]
    } else if (splitRadiology === subdropdownitems?.bedManagementAdmin) {
      opts = bedManagementOptions
    } else if (splitRadiology == subdropdownitems?.devices) {
      opts = devicesOptions
    } else if (splitRadiology == subdropdownitems?.partners) {
      opts = partnersOptions
    } else {
      opts = [...labOptions]
    }
    return opts?.sort((a, b) => (a?.label < b?.label ? -1 : 1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    splitRadiology,
    radiologyOptions,
    bedManagementOptions,
    labOptions,
    devicesOptions,
    partnersOptions,
  ])

  options?.sort?.((a, b) => (a?.label < b?.label ? -1 : 1))

  useEffect(() => {
    dispatch(setLmsAdminSearch(''))
  }, [dispatch, selectedDropdownValue, options])

  useEffect(() => {
    if (selectedDropdownValue) {
      dispatch(setLmsItem(selectedDropdownValue))
    }
  }, [dispatch, selectedDropdownValue])

  const handleChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const value = ev.target.value
    dispatch(setSelectedValue(value))
    setSelectedDropdownValue(value)
    dispatch(setLmsFormMode('none'))
    dispatch(setLmsItem(value))
  }
  const generateSubMenuItems = (
    options: { value: string; label: string }[]
  ) => {
    const valueToLinkMap: Record<string, string> = {
      [MainMenuConst?.radiologist]: radilogist,
      [MainMenuConst?.pathologist]: pathologist,
      [MainMenuConst?.proRithm]: proRithmDashboard,
      [MainMenuConst?.labDevices]: labDevices,
      [MainMenuConst?.radiologyDevices]: radiologyDevices,
      [subdropdownitems?.doctor]: doctor,
      [subdropdownitems?.referredOut]: partners,
    }
    return options?.map((option) => {
      const link =
        valueToLinkMap?.[option?.value] ||
        `/administration/${hoveredDropdownItem}`
      const handleClick = (ev: any) => {
        ev.preventDefault()
        if (option?.value in valueToLinkMap) {
          window.location.href = valueToLinkMap?.[option?.value]
        } else {
          handleChange(ev)
        }
      }
      return {
        name: option?.value,
        label: option?.label,
        onclick: handleClick,
        link,
      }
    })
  }

  const menuItems: MenuItem[] = [
    {
      name: 'patients',
      label: 'Registration',
      link: '/patients',
      dropdownItems: [
        {
          name: 'membership',
          label: 'Membership',
          link: '/membership',
        },
        {
          name: 'package',
          label: 'Package',
          link: '/package',
        },
      ],
      roles: hasRole([PATIENTS_READ, PATIENTS_WRITE])
        ? [PATIENTS_READ, PATIENTS_WRITE]
        : undefined,
    },
    {
      name: 'OPD',
      label: 'OPD',
      link: '/opd',
      dropdownItems: [
        {
          name: 'OP Visit Details',
          label: 'OP Visit Details',
          link: '/visits',
        },
      ],
      roles: hasRole([PATIENTS_READ, PATIENTS_WRITE])
        ? [PATIENTS_READ, PATIENTS_WRITE]
        : undefined,
    },
    {
      name: 'appointments',
      label: 'Appointments',
      link: '/appointments',
      dropdownItems: [
        {
          name: 'calendar',
          label: 'Calendar',
          link: 'appointments/calendar',
        },
        {
          name: 'slotManagement',
          label: 'Slot Management',
          link: 'appointments/slot-management',
        },
      ],
      roles: hasRole([APPOINTMENTS_READ, APPOINTMENTS_WRITE])
        ? [APPOINTMENTS_READ, APPOINTMENTS_WRITE]
        : undefined,
    },
    {
      name: 'laboratory',
      label: 'Laboratory',
      link: '/lms',
      dropdownItems: [
        {
          name: 'referredOut',
          label: 'Referred Out',
          link: '/lms/referred',
        },
        {
          name: 'Dashboard',
          label: 'Dashboard',
          link: '/lms/lmsView',
        },
        {
          name: 'Lab Census',
          label: 'Lab Census',
          link: '/lms/lmsCensus',
        },
        {
          name: 'Trim PDF',
          label: 'Trim PDF',
          link: '/lms/trimPdf',
        },
        {
          name: 'Lab Test Reference',
          label: 'Lab Test Reference',
          link: '/labReference',
        },
      ],
      roles: hasRole([LMS_READ]) ? [LMS_READ] : undefined,
    },
    {
      name: 'radiology',
      label: 'Radiology',
      link: '/radiology',
      dropdownItems: [
        {
          name: 'referredOut',
          label: 'Referred Out',
          link: '/radiology/referred',
        },
        {
          name: 'Trim PDF',
          label: 'Trim PDF',
          link: '/radiology/trimPdf',
        },
      ],
      roles: hasRole([RADIOLOGY_WRITE]) ? [RADIOLOGY_WRITE] : undefined,
    },
    {
      name: 'ipd',
      label: 'IPD',
      link: '/bedIpd/bedAssignmentList',
      dropdownItems: [
        {
          name: 'IPRegistration',
          label: 'IP Registration',
          link: '/ipdRegistration',
        },
        {
          name: 'Bed Allocation',
          label: 'Bed Allocation',
          link: '/bedIpd/bedOverview',
        },
        {
          name: 'bedManagement',
          label: 'Bed Dashboard',
          link: '/bedIpd/bedManagementDashboard',
        },
        {
          name: 'Bed Layout',
          label: 'Bed Layout',
          link: '/bedIpd/bedLayout',
        },
      ],
      roles: hasRole([IPD_READ]) ? [IPD_READ] : undefined,
    },
    {
      name: 'billing',
      label: 'Billing',
      link: BillingUrl,
      dropdownItems: [
        {
          name: 'pharmacy',
          label: 'Pharmacy',
          link: pharmacyUrl,
          isExternal: true,
        },
      ],
      roles: hasRole([PATIENTS_READ, PATIENTS_WRITE])
        ? [PATIENTS_READ, PATIENTS_WRITE]
        : undefined,
    },
    {
      name: 'Apps',
      label: 'Apps',
      link: '/apps',
      dropdownItems: [
        {
          name: 'Command Center',
          label: 'Command Center',
          link: COMMANDCENTER_URL,
          isExternal: true,
        },
        {
          name: 'emp 360',
          label: 'Emp 360',
          link: REACT_APP_EMP360,
          isExternal: true,
        },
        {
          name: 'Innomaint',
          label: 'Innomaint-Asset Mgmt',
          link: INNOMAINT_URL,
          isExternal: true,
        },
        {
          name: 'Innomaint - QR Code Generator',
          label: 'Innomaint - QR Code Generator',
          link: INNOMAINT_URL_QR_CODE,
          isExternal: true,
        },
        {
          name: 'RMS',
          label: 'RMS',
          link: RMS_URL,
          isExternal: true,
        },
        {
          name: 'Reporting',
          label: 'Apache superset',
          link: REPORT_URL,
          isExternal: true,
        },
        {
          name: 'Suite CRM',
          label: 'Suite CRM',
          link: suiteCRM,
          isExternal: true,
        },
      ],
      roles: hasRole([PATIENTS_READ, PATIENTS_WRITE])
        ? [PATIENTS_READ, PATIENTS_WRITE]
        : undefined,
    },
    {
      name: 'administration',
      label: 'Administration',
      link: '/administration',
      dropdownItems: [
        {
          name: 'partners',
          label: 'Partners',
          link: '/administration/bedManagementAdmin',
          subMenuItems: generateSubMenuItems(options),
        },
        {
          name: 'departments',
          label: 'Departments',
          link: '/administration/departments',
        },
        {
          name: 'bedManagementAdmin',
          label: 'Bed Management',
          link: '/administration/bedManagementAdmin',
          subMenuItems: generateSubMenuItems(options),
        },
        {
          name: 'location',
          label: 'Operating Unit',
          link: '/administration/locations',
        },
        {
          name: 'laboratory',
          label: 'Laboratory',
          link: '/administration/laboratory',
          subMenuItems: generateSubMenuItems(options),
        },
        {
          name: 'radiology',
          label: 'Radiology',
          link: '/administration/radiology',
          subMenuItems: generateSubMenuItems(options),
        },

        {
          name: 'User Management New',
          label: 'User Management',
          link: '/userdata',
        },
        {
          name: 'devices',
          label: 'Devices',
          link: '/devices',
          subMenuItems: generateSubMenuItems(options),
        },
      ],
      roles: hasRole([ADMINISTRATION_READ]) ? [ADMINISTRATION_READ] : undefined,
    },
  ]

  return (
    <div className='sideBar'>
      <ul className='sidebarMenu'>
        {menuItems?.map(
          (item) =>
            KeycloakService?.hasRole(item?.roles || []) && (
              <li
                key={item?.name}
                onMouseEnter={(e) =>
                  handleMouseEnter(
                    item?.name,
                    e?.currentTarget?.offsetTop + e.currentTarget?.offsetHeight,
                    e?.currentTarget?.offsetLeft
                  )
                }
                onMouseLeave={handleMouseLeave}
              >
                {item?.name === MainMenuConst?.billing ? (
                  <a
                    href={item?.link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {item?.label}
                  </a>
                ) : (
                  <NavLink to={item?.link}>{item?.label}</NavLink>
                )}
                {hoveredItem === item?.name && item?.dropdownItems && (
                  <div
                    className='location-dropdown'
                    style={{
                      top: dropdownPosition.top,
                      left: dropdownPosition.left,
                    }}
                  >
                    <ul className='location-dropdown-list'>
                      {item?.dropdownItems?.map((dropdownItem) => (
                        <li
                          key={dropdownItem?.name}
                          className='dropdown-item'
                          onMouseEnter={(e) =>
                            handleDropdownItemEnter(dropdownItem?.name, e)
                          }
                          onMouseLeave={handleDropdownItemLeave}
                        >
                          {dropdownItem?.isExternal ? (
                            <a
                              href={dropdownItem?.link}
                              target='_blank'
                              rel='noopener noreferrer'
                              className={`dropdown-link ${
                                hoveredDropdownItem === dropdownItem?.name
                                  ? 'hovered'
                                  : ''
                              }`}
                            >
                              {dropdownItem?.label}
                            </a>
                          ) : (
                            <NavLink
                              to={dropdownItem?.link}
                              className={`dropdown-link ${
                                hoveredDropdownItem === dropdownItem?.name
                                  ? 'hovered'
                                  : ''
                              }`}
                            >
                              {dropdownItem?.label}
                              {dropdownItem?.subMenuItems && (
                                <span className='arrow'>&#x25B6;</span>
                              )}
                            </NavLink>
                          )}
                          {dropdownItem?.subMenuItems &&
                            hoveredDropdownItem === dropdownItem?.name && (
                              <div
                                ref={(el) =>
                                  (subdropdownRef.current[dropdownItem.name] =
                                    el)
                                }
                                className='sub-dropdown'
                              >
                                <ul className='sub-dropdown-list'>
                                  {dropdownItem?.subMenuItems?.map(
                                    (subItem) => (
                                      <li
                                        key={subItem?.name}
                                        className='sub-dropdown-item'
                                      >
                                        <NavLink
                                          to={subItem?.link}
                                          className='sub-dropdown-link'
                                          onClick={() =>
                                            handleChange({
                                              target: { value: subItem?.name },
                                            } as ChangeEvent<HTMLSelectElement>)
                                          }
                                        >
                                          {subItem?.label}
                                        </NavLink>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            )
        )}
      </ul>
    </div>
  )
}

export default MainMenu
