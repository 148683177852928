import React, { useEffect, useState, useCallback, useRef } from 'react'
import { startSxpProxy } from '../../utils/api'
import {
  CHAT_PROJECT_ID,
  IPD_PROJECT_ID,
  LABS_PROJECT_ID,
  OPD_PROJECT_ID,
  REGISTRATION_PROJECT_ID,
  // RADIOLOGY_PROJECT_ID,
} from '../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../app/hooks'
import OpCaseLogo from '../../assets/icons/opcasesheet.png'
import LabRadiology from './labradiology'
import { setQueryParam } from '../membership/membershipSlice'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import OPDVisitCreateDialog from './OPDVisitCreateDialog'
import TreatmentPlanForm from './treatmentPlan'
import { weekDays } from '../opd/components/medications/medicationModels'
import { useReactToPrint } from 'react-to-print'
import medicalPrescription from '../../assets/icons/medical-prescription.png'
import EPrescription from '../patients/dashboard/EPrescription'
import OpCaseSheetPrint from '../patients/dashboard/OpCaseSheetPrint'
import {
  FetchDataError,
  InProgress,
  NewOPDObservations,
  triaged,
} from './constant'
import { STATUS } from '../Notification/Constants'
import ProvenanceView from '../../components/ProvenanceView'
import { getProvenanceData } from '../patients/utils'
import { Practitioner, Provenance } from 'fhir/r4'
import { ProvenanceData } from '../patients/patientsSlice'
import { intent as intents } from '../administration/constants'
import { create, update } from '../lms/constants'
import {
  intent,
  // radiologyIntents,
  statuses,
} from '../Radiology/Constants/constants'
// import DeviceRatingForm from './DeviceRatingForm'

interface Props {
  id: string
}
interface Appointment {
  appointmentId: string
}

export type upComingAppointmentType = {
  data: string
  docName: string
  service: string
  timets: string
}

const OPDVisits: React.FC<Props> = ({ id }) => {
  const [labOrderList, setLabOrderList] = useState<any[]>([])
  // const [radiologyOrders, setRadiologyOrdersList] = useState<any>([])
  const [patientData, setPatientData] = useState<any>({})
  const [upcomingAppointments, setUpcomingAppointments] = useState<
    upComingAppointmentType[]
  >([])
  // const [visitsList, setVisitList] = useState<appointmentType[]>([])
  // const [visitsDateList, setVisitsDateList] = useState<appointmentType[]>([])
  // const [status, setStatus] = useState<'loading' | 'loaded' | 'error'>(
  //   'loading'
  // )
  const [appointmentId, setAppointmentId] = useState<string>('')
  const [allIPDAppointments, setAllIPDAppointments] = useState<Appointment[]>(
    []
  )
  // const [selectedDate, setSelectedDate] = useState(-1)
  const [appointmentIds, setAppointmentIds] = useState<string[]>([])
  const [show, setShow] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [encounterDetails, setEncounterDetails] = useState<any>(null)
  // const [closeVisit, setCloseVisit] = useState('')
  const [visitStatusChanged, setVisitStatusChanged] = useState(false)
  const [allMedications, setAllMedications] = useState([])
  const [allIPDMedications, setAllIPDMedications] = useState([])
  const [isTableVisible, setIsTableVisible] = useState(true)
  const [isMedicationVisible, setIsMedicationsVisible] = useState(true)
  const [followUpAdvice, setFollowUpAdvice] = useState('')
  const [followUpDate, setFollowUpDate] = useState('')
  const [referralRequired, setReferralRequired] = useState<'Yes' | 'No'>('No')
  const [openTreatment, setOpenTreatment] = useState(false)
  const [treatmentPlantext, setTreatmentPlantext] = useState('')
  const [homeAdvice, setHomeAdvice] = useState('')
  const [referraldocname, setReferralDocNAme] = useState('')
  const [refdepartment, setRefDepartment] = useState('')
  const [refreasonReferral, setRefReasonReferral] = useState('')
  // const [openRating, setOpenRating] = useState(false)
  // const [selectedEncounter, setSelectedEncounter] = useState('')

  // const handleOpenRating = (id: string) => {
  //   setSelectedEncounter(id)
  //   setOpenRating(true)
  // }
  // const handleCloseRating = () => {
  //   setOpenRating(false)
  // }

  const [drInfo, setDrInfo] = useState({})
  const [historyList, setHistoryList] = useState<any>([])
  const [examinationList, setExaminationList] = useState<any>([])
  const [vitalsList, setVitalsList] = useState<any>([])
  const [diagnosisList, setDiagnosisList] = useState<any>([])
  const [medications, setMedications] = useState<any>([])
  const [isDataFetched, setIsDataFetched] = useState(false)
  const OpCaseSheetRef = useRef(null)
  const eprescriptionRef = useRef(null)
  const [encounterIds, setEncounterIds] = useState<string>('')
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})

  const toggleTable = () => {
    setIsTableVisible(!isTableVisible)
  }
  const MedicationTable = () => {
    setIsMedicationsVisible(!isMedicationVisible)
  }
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleRefresh = () => {
    setRefresh(true)
  }
  const fetchLabOrders = async (patientId: string) => {
    try {
      const intent = 'getLabOrdersByPatientIds'
      const state = {
        patientId,
      }
      const resp = await startSxpProxy(LABS_PROJECT_ID, intent, state)
      if (resp?.data?.lab_orders) {
        const orders = resp?.data?.lab_orders
        setLabOrderList(orders)
      } else {
        setLabOrderList([])
      }
    } catch (e) {
      console.error('Error fetching lab orders:', e)
    }
  }
  // const fecthRadiologyOrders = async (patientId: string) => {
  //   try {
  //     const intent = radiologyIntents?.getAllRadiologyOrdersByPatientIds
  //     const state = {
  //       patientId,
  //     }
  //     const resp = await startSxpProxy(RADIOLOGY_PROJECT_ID, intent, state)
  //     if (resp?.data?.radiology_orders?.length > 0) {
  //       const orders = resp?.data?.radiology_orders
  //       setRadiologyOrdersList(orders)
  //     } else {
  //       setRadiologyOrdersList([])
  //     }
  //   } catch (e) {
  //     console.error('Error fetching radiology orders:', e)
  //   }
  // }

  const fetchAllAppointments = async (patientId: string) => {
    try {
      const intent = 'allAppointments'
      const state = {
        id: patientId,
      }
      const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      setUpcomingAppointments(resp.data)
      const ids = resp.data.map(
        (appointment: Appointment) => appointment.appointmentId
      )
      setAppointmentIds(ids)
    } catch (e) {
      console.error('Error fetching upcoming appointments:', e)
    }
  }
  // encounter call
  const fetchEncounterForAppointments = async (appointmentIds: string) => {
    try {
      const intent = 'getEncounterForAppointments'
      const state = { appointmentIds }
      const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      setEncounterDetails(resp?.data)
      const id = resp?.data?.map((app: any) => app.encounterId)?.join(',')
      setEncounterIds(id)
    } catch (e) {
      console.error('Error fetching encounter details:', e)
    }
  }
  useEffect(() => {
    if (appointmentIds?.length > 0) {
      const appointmentIdsString = appointmentIds?.join(',')
      fetchEncounterForAppointments(appointmentIdsString)
        .then((resp) => console.log('Encounter details response:', resp))
        .catch((error) =>
          console.error('Error fetching encounter details:', error)
        )
    }
  }, [appointmentIds, visitStatusChanged])

  const fetchProvencesOfVisits = useCallback(async () => {
    if (encounterIds) {
      startSxpProxy(REGISTRATION_PROJECT_ID, intents?.fetchProvenance, {
        patientIds: encounterIds,
      })
        ?.then((data) => {
          const entries =
            data?.data?.entry?.map(
              (dde: { resource: Provenance | Practitioner }) => dde?.resource
            ) ?? []

          const obj = getProvenanceData(entries)
          setProvenances(obj)
        })
        ?.catch((err) => console.error(err))
    }
  }, [encounterIds])

  useEffect(() => {
    fetchProvencesOfVisits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encounterIds])

  const closeEncounter = async (encounterId: string) => {
    try {
      const intent = 'closeEncounterForAppointment'
      const state = { encounterId }
      await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      // setCloseVisit(resp?.data)
      setVisitStatusChanged((prev) => !prev)
    } catch (e) {
      console.error('Error closing encounter:', e)
    }
  }
  useEffect(() => {
    if (encounterDetails?.length > 0) {
      const encounterIds = encounterDetails.map((encounter: any) =>
        parseInt(encounter.encounterId, 10)
      )
      getallVisitmedication(encounterIds)
    }
  }, [encounterDetails])

  const getallVisitmedication = async (visitIds: number[]) => {
    try {
      const intent = 'getallVisitmedication'
      const state = { visitIds: visitIds }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      const medications = resp?.data?.medications
      setAllMedications(medications)
    } catch (e) {
      console.error('Error fetching medication details:', e)
      setAllMedications([])
    }
  }
  // const getVisitsListDates = async (visitedData: any) => {
  //   if (visitedData) {
  //     // const visitedDates = visitedData?.filter(
  //     //   (elm: any) => elm?.resource?.resourceType === 'Encounter'
  //     // )
  //     // setVisitsDateList(visitedDates)
  //   }
  // }
  const fetchData = useCallback(async (patientId: string) => {
    try {
      const patientIntent = intent?.getPatientApi
      const state = { id: patientId }
      const resp = await startSxpProxy(OPD_PROJECT_ID, patientIntent, state)
      setPatientData(resp?.data)
      await fetchAllAppointments(patientId)
      await fetchLabOrders(patientId)
    } catch (e) {
      console.error(FetchDataError, e)
      // setStatus('error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    fetchData(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const showvisit = (patientId: string, encounterId: string) => {
    const basePath = `/visits/${encounterId}/history`
    navigate(basePath, {
      state: {
        id: patientId,
        show: true,
      },
    })
  }
  const getDrDetails = (appointmentId: string, dataList: any) => {
    const drInfoData = {
      drName: '',
      department: '',
      date: '',
      drRegistrationNo: '',
      designation: '',
      signature: '',
    }
    const findByReference = (reference: string) =>
      dataList?.find((item: any) => item?.fullUrl?.includes(reference))

    const appointment = findByReference(appointmentId)
    if (!appointment) {
      setDrInfo(drInfoData)
      return
    }
    const slotId = appointment?.resource?.slot?.[0]?.reference
    const slot = slotId && findByReference(slotId)
    if (!slot) {
      setDrInfo(drInfoData)
      return
    }
    const scheduleId = slot?.resource?.schedule?.reference
    const schedule = scheduleId && findByReference(scheduleId)
    if (!schedule) {
      setDrInfo(drInfoData)
      return
    }
    drInfoData.date = slot?.resource?.start ?? ''

    const practitionerReference = schedule?.resource?.actor?.find(
      (actor: any) => actor?.reference?.includes('Practitioner')
    )?.reference

    const practitioner =
      practitionerReference && findByReference(practitionerReference)
    if (practitioner) {
      drInfoData.drName = practitioner?.resource?.name ?? ''
      drInfoData.designation =
        practitioner?.resource?.qualification?.[0]?.code?.text ?? ''
      drInfoData.drRegistrationNo =
        practitioner?.resource?.qualification?.[0]?.identifier?.[0]?.value ?? ''
      drInfoData.signature = practitioner?.resource?.photo?.[1]?.url ?? ''
    }

    drInfoData.department =
      appointment?.resource?.specialty?.[0]?.coding?.[0]?.display ?? ''

    setDrInfo(drInfoData)
  }

  const VisitTableRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const fetchAllAppointments = async (patientRegisteredId: string) => {
      try {
        const intent = 'getAllSubEncounters'
        const state = { patientId: patientRegisteredId }
        const resp = await startSxpProxy(IPD_PROJECT_ID, intent, state)
        const data = resp?.data
        if (data && data?.entry && Array.isArray(data.entry)) {
          const appointmentIds = data.entry
            .filter(
              (entry: any) => entry?.resource?.resourceType === 'Encounter'
            )
            .map((entry: any) => entry?.resource?.id)

          setAllIPDAppointments(appointmentIds)
        } else {
          console.error('Unexpected data format:', data)
          setAllIPDAppointments([])
        }
      } catch (e) {
        console.error('Error fetching upcoming appointments:', e)
        setAllIPDAppointments([])
      }
    }
    if (id) {
      fetchAllAppointments(id)
    }
  }, [id])
  const GetAllOPDObservations = async (resourceId: any) => {
    try {
      const intent = NewOPDObservations
      const state = {
        encounterId: resourceId,
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp?.data) {
        setVitalsList(resp?.data?.vitals)
        setHistoryList(resp?.data?.history)
        setDiagnosisList(resp?.data?.diagnosis)
        setExaminationList(resp?.data?.examination)
        setMedications(resp?.data?.medications)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handlePrint = useReactToPrint({
    content: () => OpCaseSheetRef.current,
    onAfterPrint: () => {
      if (!isDataFetched) {
        setIsDataFetched(true)
      }
    },
  })

  const handleOpCaseSheetPrint = () => {
    setIsDataFetched(false)
    handlePrint()
  }

  const fetchPatientData = async (id: string, appointmentId: any) => {
    const intentData = intent?.getPatientVisits
    const patientIntent = intent?.getPatientApi
    const state = { id }

    try {
      const res = await startSxpProxy(OPD_PROJECT_ID, intentData, state)
      if (res?.data?.entry?.length > 0) {
        // setVisitList(res?.data?.entry)
        // getVisitsListDates(res?.data?.entry)
        if (res?.data?.entry?.[0] && res?.data?.entry?.[0]?.resource) {
          const resourceId = res?.data?.entry?.[0]?.resource?.id
          // setSelectedDate(0)
          await GetAllOPDObservations(resourceId)
          await getDrDetails(`Appointment/${appointmentId}`, res.data.entry)
        }
      }
      const res1 = await startSxpProxy(OPD_PROJECT_ID, patientIntent, state)
      setPatientData(res1.data)
    } catch (e) {
      console.log('Error:', e)
    }
  }

  const handleEPrescriptionPrint = useReactToPrint({
    content: () => eprescriptionRef.current,
    // pageStyle: opCaseSheetStyle,
  })

  const getallIPDVisitmedication = async () => {
    try {
      const intent = 'getallVisitmedication'
      const state = { visitIds: allIPDAppointments }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      const medications = resp?.data?.medications
      setAllIPDMedications(medications)
    } catch (e) {
      console.error('Error fetching medication details:', e)
      setAllIPDMedications([])
    }
  }
  useEffect(() => {
    // if (allIPDAppointments?.length > 0) {
    //   const encounterIds = allIPDAppointments?.map((visitId: any) =>
    //     parseInt(visitId?.id, 10)
    //   )
    getallIPDVisitmedication()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIPDAppointments])

  const allCombinedMedications = [
    ...allMedications.map((medication: any) => ({
      ...medication,
      source: 'OPD',
    })),
    ...allIPDMedications.map((medication: any) => ({
      ...medication,
      source: 'IPD',
    })),
  ]

  useEffect(() => {
    const resizeHandler = () => {
      const container = VisitTableRef.current
      if (container) {
        const availableHeight =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 200}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
      dispatch(setQueryParam({ q: '', param: '' }))
    }
  }, [dispatch])

  const handleCreateVisitClick = (appointmentId: any) => {
    setShow(true)
    setAppointmentId(appointmentId)
  }

  const capitalizeFirstLetter = (string: any) => {
    if (!string) return ''
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)
  }

  const handleStatusChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    encounterId: string
  ) => {
    if (e.target.value === 'finished') {
      closeEncounter(encounterId)
    }
  }
  const getTreatmentPlan = async (encounterId: string) => {
    try {
      const intent = 'getTreatmentPlan'
      const state = { encounterId }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      const treatmentPlan = resp?.data?.entry?.[0]?.resource?.component ?? []
      setOpenTreatment(true)

      setTreatmentPlantext(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'treatmentPlan'
        )?.valueString ?? '-'
      )
      setFollowUpAdvice(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'followUpAdvice'
        )?.valueString ?? '-'
      )

      setFollowUpDate(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'followUpDate'
        )?.valueString ?? '-'
      )

      setHomeAdvice(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'homeAdvice'
        )?.valueString ?? '-'
      )

      setReferralRequired(
        treatmentPlan
          .find(
            (component: any) =>
              component?.code?.coding?.[0]?.code === 'referralRequired'
          )
          ?.valueString?.toLowerCase() === 'yes'
          ? 'Yes'
          : 'No'
      )
      setReferralDocNAme(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'nameOfDoctor'
        )?.valueString ?? '-'
      )
      setRefDepartment(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'department'
        )?.valueString ?? '-'
      )
      setRefReasonReferral(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'reasonReferral'
        )?.valueString ?? '-'
      )
    } catch (error) {
      console.error('Error fetching treatment plan details:', error)
    }
  }

  const handleViewClick = async (encounterId: string) => {
    try {
      await getTreatmentPlan(encounterId)
    } catch (error) {
      console.error('Error handling view click:', error)
    }
  }
  const handleCloseTreatment = () => {
    setOpenTreatment(false)
  }
  useEffect(() => {
    if (refresh === true) {
      fetchData(id)
    }
  }, [refresh, id, fetchData])

  const patientMUHId = patientData?.identifier?.[2]?.value
  return (
    <>
      {show && (
        <Dialog
          open={show}
          onClose={() => setShow(false)}
          maxWidth='sm'
          fullWidth={true}
          style={{
            alignItems: 'center',
          }}
        >
          <div
            onClick={() => setShow(false)}
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginRight: '2rem',
            }}
          >
            X
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <DialogTitle style={{ alignItems: 'center' }}>
              Visit&nbsp;Creation ({patientMUHId})
            </DialogTitle>
            <OPDVisitCreateDialog
              patientId={id}
              appointmentId={appointmentId}
              onRefresh={handleRefresh}
              onClose={() => setShow(false)}
            />
          </div>
        </Dialog>
      )}

      <div className='opd-table' onClick={toggleTable}>
        Visit History {isTableVisible ? '▲' : '▼'}
      </div>
      {isTableVisible && (
        <div className='appointments-table-container' ref={VisitTableRef}>
          <table
            className='data-table admin-table'
            style={{ position: 'relative' }}
          >
            <thead style={{ position: 'sticky', top: '0px', zIndex: 9 }}>
              <tr>
                <th className='table-w-4'>Sl&nbsp;No</th>
                <th className='text-left table-w-2'>Service</th>
                <th className='text-left table-w-2'>Visit Type</th>
                <th className='text-left table-w-2'>Doctor Name</th>
                <th className='text-left table-w-2'>Date</th>
                <th className='text-left table-w-2'>Time Slot</th>
                <th className='table-w-10'>View Visit</th>
                <th className='table-w-10'>Treatment Plan</th>
                <th className='table-w-10'>Status</th>
                <th className='table-w-10'>Created By</th>
                <th className='table-w-10'>Updated By</th>
                <th>View </th>
              </tr>
            </thead>
            <tbody>
              {upcomingAppointments?.length === 0 ? (
                <tr>
                  <td colSpan={8} style={{ textAlign: 'center' }}>
                    No Appointments found
                  </td>
                </tr>
              ) : (
                upcomingAppointments
                  ?.sort(
                    (a: any, b: any) =>
                      new Date(b.date).getTime() - new Date(a.date).getTime()
                  )
                  ?.map((appointment: any, index: any) => (
                    <tr key={appointment.appointmentId}>
                      <td className='text-center'>{index + 1}</td>
                      <td className='text-center'>{appointment.service}</td>
                      <td className='text-left'>
                        {encounterDetails && encounterDetails.length > 0 ? (
                          encounterDetails
                            ?.filter(
                              (encounter: any) =>
                                encounter?.appointmentId ===
                                appointment?.appointmentId
                            )
                            ?.map((encounter: any) => (
                              <span key={encounter?.encounterId}>
                                {encounter?.visitType
                                  ? encounter?.visitType
                                      .toLowerCase()
                                      .split(' ')
                                      .map(
                                        (word: any) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(' ')
                                  : 'General'}
                              </span>
                            ))
                        ) : (
                          <span>General</span>
                        )}
                      </td>
                      <td className='text-left'>{appointment.docName}</td>
                      <td className='text-left'>{appointment.date}</td>
                      <td className='text-left'>{appointment.time}</td>
                      <td className='text-left'>
                        {encounterDetails && encounterDetails?.length > 0 ? (
                          encounterDetails?.filter(
                            (encounter: any) =>
                              encounter?.appointmentId ===
                              appointment?.appointmentId
                          )?.length > 0 ? (
                            encounterDetails
                              ?.filter(
                                (encounter: any) =>
                                  encounter?.appointmentId ===
                                  appointment?.appointmentId
                              )
                              ?.map((encounter: any) => (
                                <a
                                  key={encounter?.encounterId}
                                  onClick={() =>
                                    showvisit(id, encounter?.encounterId)
                                  }
                                  className='view-visit-button'
                                >
                                  View
                                </a>
                              ))
                          ) : appointment?.status !== 'cancelled' ? (
                            <a
                              onClick={() =>
                                handleCreateVisitClick(
                                  appointment?.appointmentId
                                )
                              }
                              className='createvisitbutton'
                            >
                              Create Visit
                            </a>
                          ) : (
                            <span>Cancelled</span>
                          )
                        ) : appointment.status !== 'cancelled' ? (
                          <a
                            onClick={() =>
                              handleCreateVisitClick(appointment?.appointmentId)
                            }
                            className='createvisitbutton'
                          >
                            Create Visit
                          </a>
                        ) : (
                          <span>Cancelled</span>
                        )}
                      </td>

                      <td className='text-left'>
                        {encounterDetails &&
                        encounterDetails?.length > 0 &&
                        encounterDetails?.filter(
                          (encounter: any) =>
                            encounter?.appointmentId ===
                            appointment?.appointmentId
                        ).length > 0
                          ? encounterDetails
                              ?.filter(
                                (encounter: any) =>
                                  encounter?.appointmentId ===
                                  appointment?.appointmentId
                              )
                              .map((encounter: any) => (
                                <a
                                  key={encounter?.encounterId}
                                  onClick={() =>
                                    handleViewClick(encounter?.encounterId)
                                  }
                                  className='view-visit-button'
                                >
                                  View
                                </a>
                              ))
                          : '-'}
                      </td>
                      <td className='text-left' style={{ fontSize: '10px' }}>
                        {encounterDetails && encounterDetails.length > 0 ? (
                          encounterDetails
                            ?.filter(
                              (encounter: any) =>
                                encounter?.appointmentId ===
                                appointment?.appointmentId
                            )
                            ?.map((encounter: any) =>
                              encounter?.visitStatus === STATUS?.FINISHED ? (
                                <span key={encounter?.encounterId}>
                                  {encounter?.visitStatus === triaged
                                    ? InProgress
                                    : statuses?.completed}
                                </span>
                              ) : (
                                <select
                                  className='medication-input w75'
                                  key={encounter?.encounterId}
                                  onChange={(e) =>
                                    handleStatusChange(
                                      e,
                                      encounter?.encounterId
                                    )
                                  }
                                  style={{ fontSize: '10px', padding: '0px' }}
                                >
                                  <option value={encounter?.encounterId}>
                                    {encounter?.visitStatus === 'triaged'
                                      ? 'InProgress'
                                      : capitalizeFirstLetter(
                                          encounter?.visitStatus
                                        )}
                                  </option>
                                  <option value='finished'>Completed</option>
                                </select>
                              )
                            )
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                      {encounterDetails &&
                        encounterDetails.length > 0 &&
                        encounterDetails
                          ?.filter(
                            (encounter: any) =>
                              encounter?.appointmentId ===
                              appointment?.appointmentId
                          )
                          ?.map((encounter: any) => (
                            <td key={encounter?.encounterId}>
                              <ProvenanceView
                                date={
                                  provenances?.[encounter?.encounterId]
                                    ?.createdOn
                                }
                                name={
                                  provenances?.[encounter?.encounterId]
                                    ?.createdBy
                                }
                                mode={create}
                              />
                            </td>
                          ))}
                      {encounterDetails &&
                        encounterDetails.length > 0 &&
                        encounterDetails
                          ?.filter(
                            (encounter: any) =>
                              encounter?.appointmentId ===
                              appointment?.appointmentId
                          )
                          ?.map((encounter: any) => (
                            <td key={encounter?.encounterId}>
                              <ProvenanceView
                                date={
                                  provenances?.[encounter?.encounterId]
                                    ?.updatedOn
                                }
                                name={
                                  provenances?.[encounter?.encounterId]
                                    ?.updatedBy
                                }
                                mode={update}
                              />
                            </td>
                          ))}
                      <td className='uploadbtn'>
                        <div className='box-btn-panel'>
                          <div className='e-prescriptionBtn'>
                            <div className='opdvisit-opsheet'>
                              <EPrescription
                                ref={eprescriptionRef}
                                patient={patientData}
                                visitsList={drInfo}
                                diagnosisList={diagnosisList}
                                labOrderList={labOrderList}
                                medications={medications}
                              />
                            </div>
                          </div>

                          <div className='caseSheetButton'>
                            <div className='opdvisit-opsheet'>
                              <OpCaseSheetPrint
                                ref={OpCaseSheetRef}
                                visitsList={drInfo}
                                patient={patientData}
                                historyList={historyList}
                                examinationList={examinationList}
                                vitalsList={vitalsList}
                                diagnosisList={diagnosisList}
                                labOrderList={labOrderList}
                                radiologyList={[]}
                                // upcomingAppointments={upcomingAppointments}
                              />
                            </div>
                          </div>
                          {encounterDetails &&
                          encounterDetails?.length > 0 &&
                          encounterDetails?.filter(
                            (encounter: any) =>
                              encounter?.appointmentId ===
                              appointment?.appointmentId
                          )?.length > 0 ? (
                            <>
                              <div
                                onClick={async () => {
                                  await fetchPatientData(
                                    id,
                                    appointment?.appointmentId
                                  )
                                  handleOpCaseSheetPrint()
                                }}
                              >
                                <img
                                  title='OP Case Sheet'
                                  src={OpCaseLogo}
                                  alt='Print OP Case Sheet'
                                />
                              </div>
                              <div
                                onClick={async () => {
                                  await fetchPatientData(
                                    id,
                                    appointment?.appointmentId
                                  )
                                  handleEPrescriptionPrint(
                                    appointment?.appointmentId
                                  )
                                }}
                              >
                                <img
                                  title='E-Prescription'
                                  src={medicalPrescription}
                                  alt='Print E-Prescription'
                                />
                              </div>
                            </>
                          ) : (
                            '-'
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
      )}
      <br />
      <div>
        <LabRadiology
          cardType='labOrders'
          data={labOrderList}
          patientData={patientData}
        />
      </div>
      <div>
        <br />
        <div>
          <div className='opd-table' onClick={MedicationTable}>
            Medications {isTableVisible ? '▲' : '▼'}
          </div>
          {isMedicationVisible && (
            <div className='lab-rad-table'>
              <table
                className='data-table admin-table'
                style={{ position: 'relative' }}
              >
                <thead style={{ position: 'sticky', top: '0px', zIndex: 9 }}>
                  <tr>
                    <th className='sl-wd'>Sl&nbsp;No</th>
                    <th className='service-wd'>Service</th>
                    <th className='text-left table-w-25'>Drug Name</th>
                    <th className='text-left table-w-7'>Dose</th>
                    <th className='table-w-10'>Unit</th>
                    <th className='text-left table-w-15'>Route</th>

                    <th className='text-left table-w-15'>Frequency</th>
                    <th className='text-left table-w-30'>Interval</th>
                    <th className='table-w-10'>Duration</th>
                    <th className='table-w-10'>Total Qty</th>
                    <th className='text-left table-w-12'>Start Date</th>
                    <th className='text-left table-w-12'>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {allCombinedMedications?.length === 0 ? (
                    <tr>
                      <td colSpan={12} className='text-center'>
                        No Medications found
                      </td>
                    </tr>
                  ) : (
                    allCombinedMedications?.map(
                      (medication: any, index: any) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{medication?.source}</td>
                          <td>
                            {capitalizeFirstLetter(medication?.drug_name)}
                          </td>
                          <td>{medication?.dose}</td>
                          <td>
                            {capitalizeFirstLetter(medication?.dose_units)}
                          </td>
                          <td>{medication?.route}</td>
                          <td>
                            {capitalizeFirstLetter(medication?.frequency)}
                          </td>
                          <td style={{ fontSize: '10px' }}>
                            {medication?.intervals &&
                              (medication?.frequency === 'Once a day' ||
                                medication?.frequency === 'Immediately' ||
                                medication?.frequency === 'Twice a day' ||
                                medication?.frequency === 'Thrice a day' ||
                                medication?.frequency === 'Four times a day' ||
                                medication?.frequency ===
                                  'Five times a day') && (
                                <>
                                  <div className='drug-flexRow gap15'>
                                    <div className='drug-flexColumn'>
                                      <label>Breakfast</label>
                                      <div className='drug-flexRow gap18'>
                                        <input
                                          type='checkbox'
                                          name='breakfast'
                                          value='bb'
                                          readOnly
                                          className='medication-input'
                                          checked={medication?.intervals?.some(
                                            (option: any) =>
                                              option.name === 'breakfast' &&
                                              option.value === 'bb'
                                          )}
                                        />
                                        <input
                                          className='ml20'
                                          readOnly
                                          type='checkbox'
                                          name='breakfast'
                                          value='ab'
                                          checked={medication?.intervals?.some(
                                            (option: any) =>
                                              option.name === 'breakfast' &&
                                              option.value === 'ab'
                                          )}
                                        />
                                      </div>
                                    </div>

                                    <div className='drug-flexColumn ml10'>
                                      <label>Lunch</label>
                                      <div className='drug-flexRow gap17'>
                                        <input
                                          type='checkbox'
                                          name='before lunch'
                                          readOnly
                                          value='bl'
                                          checked={medication?.intervals?.some(
                                            (option: any) =>
                                              option.name === 'before lunch' &&
                                              option.value === 'bl'
                                          )}
                                        />
                                        <input
                                          type='checkbox'
                                          name='after lunch'
                                          readOnly
                                          value='al'
                                          checked={medication?.intervals?.some(
                                            (option: any) =>
                                              option.name === 'after lunch' &&
                                              option.value === 'al'
                                          )}
                                        />
                                      </div>
                                    </div>

                                    <div className='drug-flexColumn ml10'>
                                      <label>Dinner</label>
                                      <div className='drug-flexRow gap19'>
                                        <input
                                          type='checkbox'
                                          readOnly
                                          name='before dinner'
                                          value='bd'
                                          checked={medication?.intervals?.some(
                                            (option: any) =>
                                              option.name === 'before dinner' &&
                                              option.value === 'bd'
                                          )}
                                        />
                                        <input
                                          type='checkbox'
                                          name='after dinner'
                                          readOnly
                                          value='ad'
                                          checked={medication?.intervals?.some(
                                            (option: any) =>
                                              option.name === 'after dinner' &&
                                              option.value === 'ad'
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                            {medication?.intervals &&
                              (medication?.frequency === 'On alternate days' ||
                                medication?.frequency === 'Once a week' ||
                                medication?.frequency === 'Twice a week' ||
                                medication?.frequency === 'Thrice a week' ||
                                medication?.frequency === 'Four days a week' ||
                                medication?.frequency === 'Five days a week' ||
                                medication?.frequency ===
                                  'Six days a week') && (
                                <div className='drug-flexRow gap10'>
                                  {weekDays?.map((day) => (
                                    <div
                                      key={day.value}
                                      className='drug-flexColumn gap10'
                                    >
                                      <label>{day?.name}</label>
                                      <input
                                        type='checkbox'
                                        readOnly
                                        name={day?.name}
                                        value={day?.value}
                                        checked={medication?.intervals?.some(
                                          (option: any) =>
                                            option.name === day.name &&
                                            option.value === day.value
                                        )}
                                      />
                                    </div>
                                  ))}
                                </div>
                              )}
                            {medication?.intervals &&
                              medication?.duration_units == 'hour' && (
                                <div className='drug-flexRow gap10'>
                                  <div className='drug-flexColumn gap10'>
                                    {medication?.intervals[0]?.value}
                                  </div>
                                </div>
                              )}
                          </td>
                          <td>{medication?.duration}</td>
                          <td>{medication?.total_quantity}</td>
                          <td>{medication?.start_date}</td>
                          <td>{medication?.next_visit_date}</td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <Dialog
          open={openTreatment}
          aria-labelledby='dialog-title'
          style={{ height: '80vh' }}
        >
          <IconButton
            aria-label='close'
            onClick={handleCloseTreatment}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            X
          </IconButton>{' '}
          <DialogTitle id='dialog-title'>Treatment Plan Details</DialogTitle>
          <DialogContent>
            <TreatmentPlanForm
              treatmentPlan={treatmentPlantext}
              followUpAdvice={followUpAdvice}
              followUpDate={followUpDate}
              homeAdvice={homeAdvice}
              referralRequired={referralRequired}
              nameOfDoctor={referraldocname}
              department={refdepartment}
              reasonReferral={refreasonReferral}
            />
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}

export default OPDVisits
