import React, { useEffect, useState } from 'react'
import EditButton from '../../../components/EditButton'
import { useNavigate } from 'react-router-dom'
import '../admin.scss'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  fetchLocationsAsync,
  selectLocations,
} from '../../location/locationSlice'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { locationtext } from '../../patientRegstration/constants'
import { Provenance, Practitioner } from 'fhir/r4'
import { getProvenanceData } from '../../patients/utils'
import { LocationTableHeadings, intent } from '../constants'
import { create, update, menuValues } from '../../lms/constants'
import { ProvenanceData } from '../../patients/patientsSlice'
import ProvenanceView from '../../../components/ProvenanceView'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import { IconButton } from '@mui/material'
import KeycloakService from '../../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../../utils/roles'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import { DeleteConfirmationDialog } from '../../lms/components/admin/DeleteConfirmationDialog'
import { Archive } from '../../lms/components/admin/Archive'
import { Create, Edit } from '../../bedManagement/endpoints/EndPoints'
import { activeText } from '../../Radiology/Constants/constants'
type FacilityType = {
  id: number
  name: string
}
const Locations = () => {
  const [statusFilter, setStatusFilter] = useState('active')
  const [searchText, setSearchText] = useState<string>('')
  const locations = useAppSelector(selectLocations)
  // const [cluster, setcluster] = useState([])
  const [type, setType] = useState<FacilityType[]>([])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [tableData, setTableData] = useState(locations)
  const [LocationData, setLocationData] = useState<any>({})

  useEffect(() => {
    dispatch(fetchLocationsAsync())
  }, [dispatch, statusFilter])

  useEffect(() => {
    setTableData(locations)
  }, [locations])

  const handleCreate = () => {
    navigate(Create)
  }

  const handleEdit = (id?: string) => {
    if (id) {
      navigate(`${Edit}/${id}`)
    }
  }

  const handleStatusFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setStatusFilter(event?.target?.value)
  }

  useEffect(() => {
    const GetClusterAndType = async () => {
      try {
        const intent = 'getClusterandType'
        const resp = await startSxpProxy(ADMIN_PROJECT_ID, intent)
        if (resp?.data) {
          // setcluster(resp?.data?.data?.clusters)
          setType(resp?.data?.data?.facility_types)
        } else {
          // setcluster([])
        }
      } catch (e) {
        console.error(e)
      }
    }
    GetClusterAndType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const filteredLocations = tableData?.filter((l: any) => {
    const statusFilterCondition =
      statusFilter === 'all' || l?.resource?.status === statusFilter
    const nameFilterCondition =
      !searchText ||
      l?.resource?.name?.toLowerCase()?.startsWith(searchText?.toLowerCase())
    return statusFilterCondition && nameFilterCondition
  })
  filteredLocations?.sort?.((a: any, b: any) =>
    a?.resource?.name?.toLowerCase() < b.resource?.name?.toLowerCase() ? -1 : 1
  )
  const ids = filteredLocations?.map((l: any) => l?.resource?.id)?.join(',')
  useEffect(() => {
    if (ids) {
      startSxpProxy(ADMIN_PROJECT_ID, intent?.fetchProvByDoctors, {
        pracIds: ids,
      })?.then((data) => {
        const entries =
          data?.data?.entry?.map(
            (dde: { resource: Provenance | Practitioner }) => dde?.resource
          ) ?? []
        const obj = getProvenanceData(entries)
        setProvenances(obj)
      })
    }
  }, [ids])

  function handleDeleteDialogOpen(d: any) {
    setDeleteDialog(true)
    setLocationData(d)
  }

  function handleCloseDeleteDialog() {
    setDeleteDialog(false)
    setLocationData(null)
  }

  return (
    <div>
      <div
        className='button-enclosure mtminus8'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          border: 'none',
        }}
      >
        <div className='filterandtext'>
          <div className='membershipText'>{locationtext}</div>
        </div>
        <div className='filter-container'>
          <div className='mr10'>
            <select
              value={statusFilter}
              onChange={handleStatusFilterChange}
              className='h30 doctor-status'
            >
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
          </div>
          <input
            type='search'
            className='admin-search'
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
            placeholder='Search by Name'
          />
          <button className='btn btn-primaryBtn ml10 ' onClick={handleCreate}>
            {create}
          </button>
        </div>
      </div>
      <div
        className='data-table table-fixed admin-table-table-container'
        style={{ overflowY: 'auto', maxHeight: '500px' }}
      >
        <table className='data-table table-fixed admin-table relative'>
          <thead style={{ position: 'sticky', top: '0px' }}>
            <tr>
              <th className='table-w-5'>{LocationTableHeadings?.slNo}</th>
              <th className='table-w-5'>{LocationTableHeadings?.prefix}</th>
              <th className='table-w-8'>
                {LocationTableHeadings?.operatingUnit}
              </th>
              <th className='table-w-5'>{LocationTableHeadings?.type}</th>
              <th className='table-w-25'>{LocationTableHeadings?.address}</th>
              <th className='table-w-10'>{LocationTableHeadings?.gstinNo}</th>
              <th className='table-w-10'>{LocationTableHeadings?.dlNo}</th>
              <th className='text-left table-w-8'>
                {LocationTableHeadings.createdBy}
              </th>
              <th className='text-left table-w-8'>
                {LocationTableHeadings.updatedBy}
              </th>
              <th className='table-w-8'>{LocationTableHeadings?.action}</th>
            </tr>
          </thead>
          <tbody>
            {filteredLocations?.length ? (
              filteredLocations?.map((l: any, idx) => (
                <tr key={l.resource.id}>
                  <td>{idx + 1}</td>
                  <td>{l?.resource?.identifier?.[1]?.value}</td>
                  <td>{l?.resource?.name}</td>
                  <td>
                    {type?.find(
                      (t: any) =>
                        t?.id == l?.resource?.type?.[0]?.coding?.[2]?.display
                    )?.name || l?.resource?.type?.[0]?.coding?.[2]?.display}
                  </td>

                  <td>{l?.resource?.address?.line?.join(',') ?? '-'}</td>
                  <td className='text-center'>
                    {l?.resource?.type?.[0]?.coding?.[1]?.display}
                  </td>
                  <td className='text-center'>
                    {l?.resource?.type?.[0]?.coding?.[0]?.display}
                  </td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={provenances?.[l?.resource?.id]?.createdOn ?? ''}
                      name={provenances?.[l?.resource?.id]?.createdBy ?? ''}
                      mode={create}
                    />
                  </td>
                  <td>
                    <ProvenanceView
                      date={provenances?.[l?.resource?.id]?.updatedOn ?? ''}
                      name={provenances?.[l?.resource?.id]?.updatedBy ?? ''}
                      mode={update}
                    />
                  </td>
                  <td>
                    <div className='set-action-icons'>
                      {l?.resource?.status === activeText ? (
                        <IconButton
                          title='Archive'
                          size='small'
                          onClick={() =>
                            Archive(l, setTableData, menuValues?.Location)
                          }
                          disabled={
                            !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                          }
                        >
                          <ArchiveOutlinedIcon fontSize='small' />
                        </IconButton>
                      ) : (
                        <IconButton
                          title='un-Archive'
                          size='small'
                          onClick={() =>
                            Archive(l, setTableData, menuValues?.Location)
                          }
                          disabled={
                            !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                          }
                        >
                          <UnarchiveOutlinedIcon fontSize='small' />
                        </IconButton>
                      )}
                      <IconButton
                        title='Delete'
                        onClick={() => handleDeleteDialogOpen(l)}
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <DeleteOutlined />
                      </IconButton>
                      <EditButton
                        title='Edit Location'
                        onEdit={() => handleEdit(l?.resource?.id)}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className='text-center'>
                  No Locations Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {deleteDialog && (
        <DeleteConfirmationDialog
          openDialog={deleteDialog}
          closeDialog={handleCloseDeleteDialog}
          data={LocationData}
          name={LocationData?.resource?.name}
          setData={setTableData}
          item={menuValues?.Location}
        />
      )}
    </div>
  )
}

export default Locations
