import React, { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import Patients from '../features/patients/Patients'
import Appointments from '../features/appointments/Appointments'
import Slots from '../features/slots/Slots'
import Scheduler from '../features/scheduler/Scheduler'
import PatientRegistration from '../features/patientRegstration/PatientRegistration'
import Opd from '../features/opd/Opd'
import History from '../features/opd/components/History'
import Vitals from '../features/opd/components/Vitals'
import Systemic from '../features/opd/components/Systemic'
import Diagnosis from '../features/opd/components/Diagnosis'
import Medications from '../features/opd/components/medications/Medications'
import CreateVisit from '../features/appointments/components/CreateVisit'
import PatientDashboard from '../features/patients/dashboard/patientsDashboard'
import Orders from '../features/labTests/Orders'
import Lms from '../features/lms/Lms'
import LmsAdmin from '../features/lms/components/admin/LmsAdmin'
import CollectOrder from '../features/lms/components/search/CollectOrder'
import EnterOrder from '../features/lms/components/search/EnterOrder'
import Referred from '../features/lms/components/referred/Referred'
import Home from '../features/home/Home'
import SearchView from '../features/lms/components/search/SearchView'
import UnKnown from './UnKnown'
import RenderOnRole from './RenderOnRole'
import {
  APPOINTMENTS_READ,
  APPOINTMENTS_WRITE,
  CALENDAR_READ,
  CREATE_VISIT,
  LAB_ORDERS_WRITE,
  MEMBERSHIP_READ,
  MEMBERSHIP_WRITE,
  PATIENT_DASHBOARD_READ,
  PATIENT_DASHBOARD_WRITE,
  PATIENTS_READ,
  PATIENTS_WRITE,
  SLOTS_CREATE,
  SLOTS_DELETE,
  SLOTS_READ,
  TESTING_WRITE,
  RADIOLOGY_WRITE,
  PACKAGES_WRITE,
  RADIOLOGY_TESTING_WRITE,
} from '../utils/roles'
import Membership from '../features/membership/Membership'
import MembershipDashboard from '../features/membership/MembershipDashboard'
import CreateMembership from '../features/membership/createMembership/CreateMembership'
import EditPatientSearch from '../features/membership/editPatient/EditPatientSearch'
import Administration from '../features/administration/Administration'
import AdminHome from '../features/administration/AdminHome'
import Locations from '../features/administration/location/Locations'
import CreateLocation from '../features/administration/location/CreateLocation'
import Doctors from '../features/administration/doctor/Doctors'
import EditLocation from '../features/administration/location/EditLocation'
import CreateDoctors from '../features/administration/doctor/CreateDoctors'
import EditDoctors from '../features/administration/doctor/EditDoctors'
import Departments from '../features/administration/department/Departments'
import CreateDepartments from '../features/administration/department/CreateDepartments'
import EditDepartments from '../features/administration/department/EditDepartments'
import Loading from '../form/fields/Loading'
import Radiology from '../features/Radiology/Radiology'
import RadiologySearchView from '../features/Radiology/components/search/RadiologySearchView'
import CreateRadiologyOrder from '../features/Radiology/components/search/CreateRadiologyOrder'
import BedIpd from '../features/bedManagement/BedIpd'
import BedOverview from '../features/bedManagement/layout/BedOverview'
import BedManagementDashboard from '../features/bedManagement/layout/BedManagementDashboard'
import BedAssignmentList from '../features/bedManagement/layout/BedAssignmentList'
import Package from '../features/packages/packages'
import CreatePackage from '../features/packages/createPackage/createPackage'
import CreateAppointment from '../features/appointments/createAppointments'
import OPDPatients from '../features/OPDScreen/opdPatientdata'
import LmsView from '../features/lms/components/search/LmsView'
import LmsSummaryReport from '../features/lms/components/search/LmsSummaryReport'
import LabDepartments from '../features/administration/LabDepartments/LabDepartments'
import Pathologist from '../features/administration/Pathology/Pathologist'
import CreatePathologist from '../features/administration/Pathology/CreatePathologist'
import EditPathologist from '../features/administration/Pathology/EditPathologist'
import CreateLabDepartments from '../features/administration/LabDepartments/CreateLabDepartments'
import EditLabDepartments from '../features/administration/LabDepartments/EditLabDepartments'
import Myicons from '../features/Icons/icons'
import HistoryDental from '../features/opd/components/Dental/HistoryDental'
import OpdDental from '../features/opd/components/Dental/OpdDental'
import OpdPhysio from '../features/opd/components/Physiotherapy/OpdPhysio'
import HistoryPhysio from '../features/opd/components/Physiotherapy/HistoryPhysio'
import VitalsDental from '../features/opd/components/Dental/VitalsDental'
import DiagnosisPhysio from '../features/opd/components/Physiotherapy/DiagnosisPhysio'
import VitalsPhysio from '../features/opd/components/Physiotherapy/VitalsPhysio'
import DiagnosisDental from '../features/opd/components/Dental/DiagnosisDental'
import SystematicDental from '../features/opd/components/Dental/SystematicDental'
import PainHistoryPhysio from '../features/opd/components/Physiotherapy/PainHistoryPhysio'
import ExaminationPhysio from '../features/opd/components/Physiotherapy/ExaminationPhysio'
import TreatmentPlanPhysio from '../features/opd/components/Physiotherapy/TreatmentPlanPhysio'
import ClinicalExamination from '../features/opd/components/Dental/ClinicalExamination'
import ObjectivePhysio from '../features/opd/components/Physiotherapy/ObjectivePhysio'
import IpdVisit from '../features/bedManagement/IpdVisit/IpdVisit'
import TrimPDF from '../features/lms/components/dashboard/TrimPDF'
import IpdVisitForm from '../features/bedManagement/IpdVisit/IpdVisitForm'
import IpdVitals from '../features/bedManagement/IpdVisit/IpdVitals'
import IpdDiagnosis from '../features/bedManagement/IpdVisit/IpdDiagnosis'
import IPDPatientVisit from '../features/bedManagement/layout/IPDVisit'
import IpdTreatmentPlan from '../features/bedManagement/IpdVisit/IpdTreatmentPlan'
import OPDVisitsDetails from '../features/OPDScreen/visitDetails'
import IpdVisitsDetails from '../features/bedManagement/IpdVisit/IpdVisitDetails'
import IpdDashBoard from '../features/bedManagement/IpdVisit/IpdDashboard'
import LabReference from '../features/lms/components/search/labsamplesTests'
import CreateLabOrder from '../features/lms/components/search/CreateLabOrder'
import UserCreationForm from '../features/administration/UserCreation/UserForm'
import OpdOrder from '../features/labTests/OPDOrders'
import IpdRegistration from '../features/ipRegistration/IpdRegistration'
import IpdBedAllocation from '../features/ipRegistration/IpdBedAllocation'
import AdminMenu from '../features/administration/AdminMenu'
import InvasiveLines from '../features/InvasiveLines/InvasiveLines'
import BedLayout from '../features/bedManagement/BedLayout'
import ProgressNote from '../features/bedManagement/IpdVisit/IpdProgressNote/ProgressNoteForm'
import InitialAssessment from '../features/bedManagement/IpdVisit/IpdSummary/InitialAssessment/InitialAssessment'
import ProRithmDasboard from '../features/ProRithm/ProRithmDasboard'
import CreateOrganizationForm from '../features/organization/CreateOrganizationForm'
import Organization from '../features/organization/Organization'
import EditOrganization from '../features/organization/EditOrganization'
import EnterRadiologyOrder from '../features/Radiology/components/search/EnterRadiologyOrder'
import RadiologyReferred from '../features/Radiology/components/RadiologyReferred'
import RadiologyOrder from '../features/Radiology/components/search/RadiologyOrder'
import Radiologist from '../features/administration/radiologist/Radiologist'
import RadiologistForm from '../features/administration/radiologist/RadiologistForm'
import NewUserDataTable from '../features/administration/UserCreation/UserDataTable'

const LmsDashboard = lazy(
  () => import('../features/lms/components/dashboard/LmsDashboard')
)
const RadiologyDashboard = lazy(
  () => import('../features/Radiology/components/dashboard/RadiologyDashboard')
)
const BedManagement = lazy(
  () => import('../features/bedManagement/layout/BedOverview')
)

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/icons' element={<Myicons />} />
      <Route path='/home' element={<Home />} />
      <Route
        path='/patients'
        element={
          <RenderOnRole
            roles={[PATIENTS_READ, PATIENTS_WRITE]}
            element={<Patients />}
          />
        }
      />
      <Route path='/patientipdvisit/:patientId' element={<IPDPatientVisit />} />
      <Route
        path='/patient-registration'
        element={
          <RenderOnRole
            roles={[PATIENTS_WRITE]}
            element={<PatientRegistration />}
          />
        }
      />
      <Route
        path='/patient-registration/:id'
        element={
          <RenderOnRole
            roles={[PATIENTS_WRITE]}
            element={<PatientRegistration />}
          />
        }
      />
      <Route
        path='/appointments'
        element={
          <RenderOnRole
            roles={[APPOINTMENTS_READ, APPOINTMENTS_WRITE]}
            element={<Appointments />}
          />
        }
      />
      <Route
        path='/appointments/createAppointment'
        element={
          <RenderOnRole
            roles={[APPOINTMENTS_READ, APPOINTMENTS_WRITE]}
            element={<CreateAppointment />}
          />
        }
      />
      <Route
        path='/appointments/:appId/create-visit'
        element={
          <RenderOnRole roles={[CREATE_VISIT]} element={<CreateVisit />} />
        }
      />
      <Route
        path='/package'
        element={
          <RenderOnRole roles={[PACKAGES_WRITE]} element={<Package />} />
        }
      />
      <Route path='/package/create' element={<CreatePackage />} />
      <Route
        path='appointments/slot-management'
        element={
          <RenderOnRole
            roles={[SLOTS_READ, SLOTS_CREATE, SLOTS_DELETE]}
            element={<Slots />}
          />
        }
      />
      <Route
        path='appointments/calendar'
        element={
          <RenderOnRole roles={[CALENDAR_READ]} element={<Scheduler />} />
        }
      />
      <Route
        path='/patient-dashboard/:id'
        element={
          <RenderOnRole
            roles={[PATIENT_DASHBOARD_READ, PATIENT_DASHBOARD_WRITE]}
            element={<PatientDashboard />}
          />
        }
      />
      <Route path='/visits' element={<OPDVisitsDetails />} />
      <Route path='/opd' element={<OPDPatients />} />
      <Route path='/visits/:id' element={<Opd />}>
        <Route path='history' element={<History />} />
        <Route path='vitals' element={<Vitals />} />
        <Route path='systemic' element={<Systemic />} />
        <Route path='diagnosis' element={<Diagnosis />} />
        <Route path='treatmentPhysio' element={<TreatmentPlanPhysio />} />
        <Route path='medications' element={<Medications />} />
        <Route path='lab-orders' element={<OpdOrder />} />
        <Route
          path='radiology-orders'
          element={<RadiologyOrder modeType={'visit'} originMode={'OPD'} />}
        />
      </Route>
      <Route
        path='/visits/:id/dental'
        element={<OpdDental appointmentId={''} doctorId={''} />}
      >
        <Route path='historyDental' element={<HistoryDental />} />
        <Route path='vitalsDental' element={<VitalsDental />} />
        <Route path='systemicDental' element={<SystematicDental />} />
        <Route path='clinicalExamination' element={<ClinicalExamination />} />
        <Route path='diagnosisDEntal' element={<DiagnosisDental />} />
        <Route path='treatmentPhysio' element={<TreatmentPlanPhysio />} />
        <Route path='medications' element={<Medications />} />
        <Route path='lab-orders' element={<OpdOrder />} />
        <Route
          path='radiology-orders'
          element={<RadiologyOrder modeType={'visit'} originMode={'OPD'} />}
        />
      </Route>
      <Route path='/visits/:id/physio' element={<OpdPhysio />}>
        <Route path='historyPhysio' element={<HistoryPhysio />} />
        <Route path='vitalsPhysio' element={<VitalsPhysio />} />
        <Route path='painHistoryPhysio' element={<PainHistoryPhysio />} />
        <Route path='examinationPhysio' element={<ExaminationPhysio />} />
        <Route path='objectivePhysio' element={<ObjectivePhysio />} />
        <Route path='diagnosisPhysio' element={<DiagnosisPhysio />} />
        <Route path='treatmentPhysio' element={<TreatmentPlanPhysio />} />
        <Route path='medications' element={<Medications />} />
        <Route path='lab-orders' element={<OpdOrder />} />
        <Route
          path='radiology-orders'
          element={<RadiologyOrder modeType={'visit'} originMode={'OPD'} />}
        />
      </Route>
      <Route path='/visits/ipdVisit/:id' element={<IpdVisit />}>
        <Route path='diagnosisIpd' element={<IpdDiagnosis />} />
        <Route path='vitals' element={<IpdVitals />} />
        <Route path='treatmentPlan' element={<IpdTreatmentPlan />} />
        <Route path='medications' element={<Medications />} />
        <Route path='lab-orders' element={<Orders />} />
        <Route
          path='radiology-orders'
          element={<RadiologyOrder modeType={'visit'} originMode={'IPD'} />}
        />

        <Route path='invasiveLineIpd' element={<InvasiveLines />} />
        <Route path='progressnote' element={<ProgressNote />} />
        <Route path='initialAssessment' element={<InitialAssessment />} />
      </Route>
      <Route path='/ipdVisit/visitDetails/:id' element={<IpdVisitsDetails />}>
        <Route path='diagnosisIpd' element={<IpdDiagnosis />} />
        <Route path='vitals' element={<IpdVitals />} />
        <Route path='treatmentPlan' element={<IpdTreatmentPlan />} />
        <Route path='medications' element={<Medications />} />
        <Route path='lab-orders' element={<Orders />} />
        <Route
          path='radiology-orders'
          element={<RadiologyOrder modeType={'visit'} originMode={'IPD'} />}
        />
      </Route>
      <Route path='/lms' element={<Lms />}>
        <Route
          path=''
          element={
            <Suspense fallback={<Loading />}>
              <LmsDashboard />
            </Suspense>
          }
        />
        <Route
          path='dashboard'
          element={
            <Suspense fallback={<Loading />}>
              <LmsDashboard />
            </Suspense>
          }
        />
        <Route path='search' element={<SearchView />}>
          <Route
            path='createorder/:id'
            element={
              <RenderOnRole
                roles={[LAB_ORDERS_WRITE]}
                element={<CreateLabOrder />}
              />
            }
          />
          <Route path='collect/:id' element={<CollectOrder />} />
          <Route path='results/:id' element={<EnterOrder />} />
        </Route>
        <Route path='lmsView' element={<LmsView />} />
        <Route path='lmsCensus' element={<LmsSummaryReport />} />
        <Route path='trimPdf' element={<TrimPDF />} />
        <Route
          path='referred'
          element={
            <RenderOnRole roles={[TESTING_WRITE]} element={<Referred />} />
          }
        />
      </Route>
      <Route path='/administration/referredOut' element={<Organization />} />
      <Route
        path='/administration/referredOut/create'
        element={<CreateOrganizationForm />}
      />
      <Route
        path='/administration/referredOut/edit/:id'
        element={<EditOrganization />}
      />
      <Route path='/labReference' element={<LabReference />} />
      <Route
        path='/membership'
        element={
          <RenderOnRole
            roles={[MEMBERSHIP_READ, MEMBERSHIP_WRITE]}
            element={<Membership />}
          />
        }
      />
      <Route
        path='/membership-dashboard/:id'
        element={<MembershipDashboard />}
      />
      <Route path='/userdata' element={<NewUserDataTable />} />
      <Route path='/membership-edit/:id' element={<EditPatientSearch />} />
      <Route path='/membership/create' element={<CreateMembership />} />
      <Route path='/administration' element={<Administration />}>
        <Route path='' element={<AdminHome />} />
        <Route path='locations' element={<Locations />} />
        <Route path='locations/create' element={<CreateLocation />} />
        <Route path='locations/edit/:id' element={<EditLocation />} />
        <Route path='/administration/doctors' element={<Doctors />} />
        <Route path='doctors/create' element={<CreateDoctors />} />
        <Route path='doctors/edit/:id' element={<EditDoctors />} />
        <Route path='referredOut/edit/:id' element={<EditOrganization />} />
        <Route path='departments/edit/:id' element={<EditDepartments />} />
        <Route path='departments' element={<Departments />} />
        <Route path='departments/create' element={<CreateDepartments />} />
        <Route path='departments/edit/:id' element={<EditDepartments />} />
        <Route path='laboratory' element={<LmsAdmin />} />
        <Route path='labs/admin' element={<AdminMenu />} />
        <Route path='radiology' element={<LmsAdmin />} />
        <Route path='bedManagementAdmin' element={<LmsAdmin />} />
        <Route path='slot-management' element={<Slots />} />
        <Route path='pathologist' element={<Pathologist />} />
        <Route path='pathologist/create' element={<CreatePathologist />} />
        <Route path='pathologist/edit/:id' element={<EditPathologist />} />
        <Route path='labDepartments' element={<LabDepartments />} />
        <Route path='userCreation' element={<UserCreationForm />} />
        <Route
          path='labDepartments/create'
          element={<CreateLabDepartments />}
        />
        <Route
          path='labDepartments/edit/:id'
          element={<EditLabDepartments />}
        />
      </Route>
      <Route path='/administration/radiologist' element={<Radiologist />} />
      <Route
        path='/administration/radiologist/create'
        element={<RadiologistForm />}
      />
      <Route
        path='/administration/radiologist/edit/:id'
        element={<RadiologistForm />}
      />
      <Route path='/appointments' element={<Appointments />}>
        <Route path='' element={<Appointments />} />
        <Route path='calendar' element={<Scheduler />} />
        <Route path='slot-management' element={<Slots />} />
      </Route>
      <Route
        path='/radiology'
        element={
          <RenderOnRole roles={[RADIOLOGY_WRITE]} element={<Radiology />} />
        }
      >
        <Route
          path=''
          element={
            <Suspense fallback={<Loading />}>
              <RadiologyDashboard />
            </Suspense>
          }
        />
        <Route
          path='dashboard'
          element={
            <Suspense fallback={<Loading />}>
              <RadiologyDashboard />
            </Suspense>
          }
        />
        <Route path='search' element={<RadiologySearchView />}>
          <Route
            path='create/:id'
            element={
              <RenderOnRole
                roles={[RADIOLOGY_WRITE]}
                element={<CreateRadiologyOrder />}
              />
            }
          />
          <Route path='results/:id' element={<EnterRadiologyOrder />} />
        </Route>
        <Route
          path='referred'
          element={
            <RenderOnRole
              roles={[RADIOLOGY_TESTING_WRITE]}
              element={<RadiologyReferred />}
            />
          }
        />
        <Route path='trimPdf' element={<TrimPDF />} />
      </Route>
      <Route path='/bedIpd' element={<BedIpd />}>
        <Route
          path=''
          element={
            <Suspense fallback={<Loading />}>
              <BedOverview />
            </Suspense>
          }
        />
        <Route path='bedOverview' element={<BedOverview />} />
        <Route
          path='bedManagementDashboard'
          element={<BedManagementDashboard />}
        />
        <Route path='bedLayout' element={<BedLayout />} />
        <Route path='bedAssignmentList' element={<BedAssignmentList />} />
        <Route path='ipVisit/create' element={<IpdVisitForm />} />
        <Route path='ipVisit/dashboard' element={<IpdDashBoard />} />
        <Route path='ipVisit/visitDetails' element={<IpdVisitsDetails />} />
        <Route path='assignBed' element={<BedManagement />} />
      </Route>
      <Route path='ipdRegistration' element={<IpdRegistration />} />
      <Route
        path='ipdRegistration/bedAllocation'
        element={<IpdBedAllocation />}
      />
      <Route path='proRithmDashboard' element={<ProRithmDasboard />} />
      <Route path='/administration/referredOut' element={<Organization />} />
      <Route
        path='/administration/referredOut/create'
        element={<CreateOrganizationForm />}
      />
      <Route
        path='/administration/referredOut/edit/:id'
        element={<EditOrganization />}
      />
      <Route path='*' element={<UnKnown />} />
    </Routes>
  )
}

export default AppRoutes
